/* ************************
   03.3: Blog
   ********************* */

    .single-post-item {
        @extend %box-shadow;
        @extend %white-bg;
        @extend %trans3;
        overflow: hidden;
        border-radius: 7px;
        margin-bottom: 30px;
        &.mbs2 {
            margin-bottom: 60px;
        }
        .post-thumbnail {
            img {
                width: 100%;
            }
        }
        .post-content {
            padding: 20px 35px 23px;
            .post-title {
                margin-bottom: 10px;
                line-height: 1.416;
            }

            .post-meta,
            .post-meta-bottom {
                @extend %list-unstyled;
                display: flex;
                align-items: center;
                li {
                    &:not(:last-child) {
                        margin-right: 25px;
                        @include medium {
                            margin-right: 14px;
                        }
                    }
                    a {
                        text-transform: uppercase;
                        @extend %semi-bold;
                    }
                }
            }
            .post-meta {
                margin-bottom: 5px;
                a {
                    @extend %c1;
                    font-size: 12px;
                }
            }
            .post-meta-bottom {
                a {
                    font-size: 13px;
                }
            }
        }

        &:hover {
            box-shadow: 7px 7px 45px rgba(#000, 0.07);
        }

        &.style--two {
            box-shadow: none;
            .post-content {
                padding: 0;
                background-color: transparent;
                margin-bottom: -5px;
                .post-meta {
                    padding-top: 21px;
                    margin-bottom: 5px;
                    li {
                        text-transform: uppercase;
                        margin-right: 0;
                        position: relative;
                        &:not(:last-child) {
                            &:after {
                                content: "/";
                                color: #A3A3A3;
                                margin: 0 3px;
                            }
                        }
                        a {
                            color: #A3A3A3;
                            font-size: 14px;
                            @extend %regular;
                            &:hover {
                                @extend %c1;
                            }
                        }
                    }
                }
            }

            .btn-inline {
                text-transform: uppercase;
                font-size: 13px;
                @extend %semi-bold;
                svg {
                    margin-right: 5px;
                    margin-left: 0;
                    position: relative;
                    top: -1px;
                    path {
                        fill: $c1;
                    }
                }
                &:hover {
                    @extend %c1;
                }
            }
        }
        &.style--three {
            box-shadow: none;
            .post-content {
                padding: 0;
                background-color: transparent;
                margin-bottom: -5px;
                .post-title {
                    a {
                        &:hover {
                            color: #0364FF;
                        }
                    }
                }
                .post-meta {
                    padding-top: 21px;
                    margin-bottom: 5px;
                    li {
                        text-transform: uppercase;
                        margin-right: 0;
                        position: relative;
                        &:not(:last-child) {
                            &:after {
                                content: "/";
                                color: #A3A3A3;
                                margin: 0 3px;
                            }
                        }
                        a {
                            color: #A3A3A3;
                            font-size: 14px;
                            @extend %regular;
                            &:hover {
                                color: #0364FF;
                            }
                        }
                    }
                }
            }

            .btn-inline {
                text-transform: uppercase;
                font-size: 13px;
                @extend %semi-bold;
                svg {
                    margin-right: 5px;
                    margin-left: 0;
                    position: relative;
                    top: -1px;
                    path {
                        fill: #0364FF;
                    }
                }
                &:hover {
                    color: #0364FF;
                }
            }
        }
    }

    /* Blog Details */
    .post-details .entry-header .entry-thumbnail {
        margin-bottom: 50px;
    }
    
    .post-details .entry-header .entry-title {
        font-size: 36px;
        line-height: 1.28;
        margin-bottom: 15px;
        font-weight: 900;
    }

    .entry-footer {
        display: flex;
        justify-content: space-between;
        @include mobileSm {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            > div {
                width: 100%;
                &:not(:last-child) {
                    border-bottom: 1px solid #dee2e6;
                    padding-bottom: 16px;
                    margin-bottom: 16px;
                }
            }
        }
        .social-share {
            p {
                strong {
                    @extend %title-color;
                }
            }
            a {
                height: auto;
                width: auto;
                border: none;
                i {
                    color: $text-color;
                }
                &:hover {
                    background: transparent;
                    i {
                        color: #00C3FF;
                    }
                }
            }
        }
    }
    
    .post-details .entry-header .entry-meta {
        margin-bottom: 30px;
    }
    
    .post-details .entry-header .entry-meta li {
        display: inline-block;
        a {
            @extend %text-color;
            &:hover {
                @extend %c1;
            }
        }
    }

    .post-details .entry-header .entry-meta > li:not(:last-child) {
        margin-right: 24px;
    }
    
    .post-details .entry-header .entry-meta li span {
        font-weight: 700;
        margin-right: 7px;
        @extend %heading-font;
        @extend %title-color
    }
    
    .post-details .entry-tags ul li:first-child,
    .post-details .entry-categories ul li:first-child {
        font-size: 16px;
        font-weight: 700;
        margin-right: 7px;
        @extend %title-color;
    }
    
    .post-details .entry-tags ul li a,
    .post-details .entry-categories ul li a {
        font-size: 14px;
        line-height: 1.57;
    }
    
    .post-details .entry-tags ul li a:not(:last-child):after,
    .post-details .entry-categories ul li a:not(:last-child):after {
        content: ',';
    }
    
    /* Blog Details Content */
    .entry-content h1,
    .entry-content h2,
    .entry-content h3,
    .entry-content h4,
    .entry-content h5,
    .entry-content h6,
    .entry-content .woocommerce-checkout .form-row.notes label,
    .woocommerce-checkout .form-row.notes .entry-content label,
    .entry-content .login-register-wrapper .tab-btn li,
    .login-register-wrapper .tab-btn .entry-content li,
    .comment-content h1,
    .comment-content h2,
    .comment-content h3,
    .comment-content h4,
    .comment-content h5,
    .comment-content h6,
    .comment-content .woocommerce-checkout .form-row.notes label,
    .woocommerce-checkout .form-row.notes .comment-content label,
    .comment-content .login-register-wrapper .tab-btn li,
    .login-register-wrapper .tab-btn .comment-content li,
    .page--content h1,
    .page--content h2,
    .page--content h3,
    .page--content h4,
    .page--content h5,
    .page--content h6,
    .page--content .woocommerce-checkout .form-row.notes label,
    .woocommerce-checkout .form-row.notes .page--content label,
    .page--content .login-register-wrapper .tab-btn li,
    .login-register-wrapper .tab-btn .page--content li {
        line-height: 1.5;
        margin-bottom: 20px;
    }

    
    .entry-content h1,
    .entry-content h2,
    .entry-content h3,
    .entry-content h4,
    .entry-content h5,
    .entry-content h6 {
        padding-top: 20px;
        margin-bottom: 18px;
    }
    
    .entry-content p,
    .comment-content p,
    .page--content p {
        margin-bottom: 30px;
    }
    
    .entry-content table,
    .comment-content table,
    .page--content table {
        margin-bottom: 30px;
    }
    
    .entry-content select,
    .comment-content select,
    .page--content select {
        margin-bottom: 30px;
    }
    
    .entry-content > *:last-child,
    .comment-content > *:last-child,
    .page--content > *:last-child {
        margin-bottom: 0;
    }
    
    // .entry-content a:hover,
    // .comment-content a:hover,
    // .page--content a:hover {
    //     text-decoration: underline;
    // }

    .comment-content {
        a {
            @extend %semi-bold;
            i {
                margin-right: 7px;
                font-size: 14px;
            }
            &:hover {
                i {
                    @extend %c1;
                }
            }
        }
    }
    
    /* Post Author Info */
    .post-author {
        padding: 45px 0 33px;
    }
    
    @media only screen and (max-width: 575px) {
        .post-author {
            flex-direction: column;
            align-items: flex-start !important;
        }
    }
    
    .post-author .author-thumb {
        margin-right: 30px;
    }
    
    @media only screen and (max-width: 575px) {
        .post-author .author-thumb {
            margin-bottom: 20px;
        }
    }
    
    .post-author .author-info h4 {
        font-size: 18px;
        margin-bottom: 5px;
    }
    
    /* Post Navigation */
    .post-navigation {
        .nav-link {
            flex-basis: 50%;
            max-width: 50%;
            padding: 0;
            &:first-child {
                padding-right: 10px;
            }
            &:last-child {
                padding-left: 10px;
                .nav-title {
                    i {
                        margin-right: 0;
                        margin-left: 10px;
                    }
                }
            }
        }
    }
    
    .post-navigation .nav-link .nav-title {
        display: block;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 17px;
        @extend %text-color;
        &:hover {
            @extend %c1;
        }
    }
    
    .post-navigation .nav-link .nav-title i {
        margin-right: 10px;
    }
    
    .post-navigation .nav-link .nav-title.text-right i {
        margin-left: 10px;
    }
    
    .post-navigation .nav-link .posted-on {
        margin: 10px 0 2px;
        display: block;
        font-size: 14px;
    }
    
    .post-navigation .nav-link:hover .nav-title {
        opacity: 1;
    }
    
    /* Related Post */
    .related-post h2 {
        font-size: 30px;
        margin-bottom: 30px;
    }
    
    /* Post Comments */
    .comments-area .comments-title {
        margin-bottom: 42px;
    }
    
    .comments-area ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    
    .comments-area .comment .single-comment {
        padding-bottom: 27px;
        margin-bottom: 30px;
        border-bottom: 1px solid #e2e2e2;
    }
    
    @media only screen and (max-width: 575px) {
        .comments-area .comment .single-comment {
            flex-direction: column;
        }
    }
    
    .comments-area .comment .single-comment .comment-author-image {
        min-width: 65px;
        margin-right: 30px;
        border-radius: 50%;
        overflow: hidden;
    }
    
    @media only screen and (max-width: 575px) {
        .comments-area .comment .single-comment .comment-author-image {
            margin-bottom: 16px;
        }
    }
    
    .comments-area .comment .single-comment:not(.trackback):not(.pingback) .comment-content {
        max-width: calc(100% - 95px);
        width: 100%;
    }
    
    @media only screen and (max-width: 575px) {
        .comments-area .comment .single-comment:not(.trackback):not(.pingback) .comment-content {
            max-width: 100%;
        }
    }
    
    .comments-area .comment .single-comment .comment-content .author_name {
        margin-right: 10px;
        margin-bottom: 0;
    }
    
    .comments-area .comment .single-comment .comment-content .commented-on {
        font-size: 12px;
    }
    
    .comments-area .comment .single-comment .comment-content p {
        margin: 7px 0 5px;
    }
    
    .comments-area .comment ul.children {
        margin-left: 95px;
    }
    
    @media only screen and (max-width: 575px) {
        .comments-area .comment ul.children {
            margin-left: 20px;
        }
    }
    
    /* Comments Form */
    .comment-respond .comment-reply-title {
        margin-bottom: 25px;
    }
    
    .comment-respond .comment-form .comment-notes {
        margin-bottom: 42px;
    }
    
    .comment-respond .comment-form .entry-content select, .entry-content .comment-respond .comment-form select,
    .comment-respond .comment-form .comment-content select, .comment-content .comment-respond .comment-form select,
    .comment-respond .comment-form .page--content select, .page--content .comment-respond .comment-form select {
        margin-bottom: 35px;
    }

    .comment-respond .comment-form .form-control {
        border: 1px solid #EEEEEE;
        margin-bottom: 20px;
    }
    
    .comment-respond .comment-form .custom-checkbox {
        margin-bottom: 34px;
        margin-top: 4px;
    }
    
    .comment-respond p.custom-checkbox, .comment-respond p.color-container {
        display: block;
        white-space: normal;
    }