/* ************************
   02.11: Preloader
   ********************* */

   .preloader {
       position: fixed;
       width: 100%;
       height: 100%;
       left: 0;
       top: 0;
       @extend %title-color-bg;
       z-index: 99999;
       display: flex;
       align-items: center;
       justify-content: center;

       .spinner-grow {
           @extend %c1-bg;
       }
   }