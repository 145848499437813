/* ************************
   04.11: Contact
   ********************* */

    .company-logo-section {
        padding-bottom: 240px;
        @include tab {
            padding-bottom: 120px;
        }
    }
    .google-map-section {
        margin-top: -120px;
        @include tab {
            margin-top: -60px;
        }
    }

    .map-wrap {
        position: relative;
        padding-bottom: 55px;
        @include tab {
            padding-bottom: 0;
        }
        .map {
            @extend %radius-10;
        }
    }

    .contact-form-wrap {
        @extend %white-bg;
        @extend %radius-10;
        max-width: 445px;
        box-shadow: 10px 10px 90px rgba(#000, .1);
        padding: 57px 45px 60px;
        position: absolute;
        right: 60px;
        top: 60px;
        @include tab {
            position: static;
            margin-top: 60px;
            max-width: 100%;
        }
        @include mobileSm {
            padding: 37px 25px 40px;
        }

        h3 {
            margin-bottom: 18px;
        }
        > p {
            margin-bottom: 38px;
        }
    }

    .contact-form {
        .form-control {
            margin-bottom: 24px;
        }
        textarea.form-control {
            height: 80px;
        }
    }

    .contact-box {
        border: 1px solid #707070;
        @extend %radius-10;
        text-align: center;
        padding: 40px 15px 37px;
        margin-bottom: 30px;

        .contact-icon {
            margin-bottom: 20px;
        }
        .contact-info {
            h3 {
                margin-bottom: 20px;
                font-size: 21px;

            }
            a {
                display: block;
            }
        }
    }