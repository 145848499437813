/* ************************
   02.8: Titles
   ********************* */

   .section-title {
      position: relative;
      margin-bottom: 67px;
      @include tab {
         margin-bottom: 47px;
      }
      &.style--two {
         h2 {
            margin-bottom: 29px;
         }
         p {
            &:not(:last-child) {
               margin-bottom: 42px;
            }
         }
         margin-bottom: 39px;
      }
      &.feature-title {
         h2 {
            font-size: 42px;
         }
      }
      &.feature-title2 {
         h2 {
            margin-bottom: 28px;
         }
         p {
            &:not(:last-child) {
               margin-bottom: 26px;
            }
         }
         .btn {
            margin-top: 16px;
         }
      }
      &.style--three {
         h5 {
            @extend %c1;
            @extend %heading-font;
            @extend %regular;
            margin-bottom: 29px;
            &.mb-n1 {
               margin-bottom: 25px;
            }
         }
         h2 {
            margin-bottom: 29px;
         }
         p {
            &:not(:last-child) {
               margin-bottom: 42px;
            }
         }
         margin-bottom: 0;
         @include tab {
            margin-bottom: 60px;
         }
      }
      &.price-plan {
         margin-bottom: 46px;
      }
      &.border-bottom {
         padding-bottom: 27px;
         border-color: rgba(0, 195, 255, 0.2);
         margin-bottom: 24px;
      }
      &.c4 {
         h5 {
            color: #0364FF;
         }
      }
      @include mobileLg {
         br {
            display: none;
         }
      }
      h5 {
         @extend %body-font;
         text-transform: uppercase;
         @extend %semi-bold;
         margin-bottom: 24px;
         margin-top: -2px;
      }

      h4 {
         font-size: 21px;
         @extend %text-color;
         margin-bottom: 20px;
      }

      h2 {
         margin-top: -9px;
         margin-bottom: 25px;
         font-weight: 900;
         font-size: 48px;
         letter-spacing: -1px;
         @include mobileSm {
            font-size: 36px;
         }
         span {
            @extend %c2;
         }
      }
      &.text-white {
         h2, h3, h4, h5, h6, p {
            @extend %white;
         }
      }
   }
