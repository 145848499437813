// Colors
$c1: #00C3FF;
$c2: #03A87C;
$title-color: #111111;
$text-color: #666666;
$border-color: #E8E8E8;
$white: #ffffff;
$black: #000000;
$body_bg: #ffffff;

// Fonts
$heading_font: 'Lato', sans-serif;
$body_font: 'Open Sans', sans-serif;

// Font Weight
$thin: 100;
$extra-light: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;
$black-bold: 900;

// Font Size
$base-font-size: 16px;
$h1_font_size: 48px;
$h2_font_size: 42px;
$h3_font_size: 24px;
$h4_font_size: 18px;
$h5_font_size: 16px;
$h6_font_size: 14px;

// Line Height
$base-line-height: calc(26 / 16);
$title-line-height: calc(57 / 48);
