/*
	*******************
	Template name:  Hostpack
	Version:        1.0
	Author:         Themelooks
	Author url:     http://themelooks.com

	NOTE:
	-----
	Please DO NOT EDIT THIS CSS, you may need to use "custom.css" file for writing your custom css.
	We may release future updates so it will overwrite this file. it's better and safer to use "custom.css".

    ******** CSS INDEX ********
    01. Base
        1.1 Reset 
        1.2 Spacing
        1.3 Color
    02. Components
        2.1 Helper
        2.2 Animation
        2.3 Social List
        2.4 Inputs
        2.5 Buttons
        2.6 Cards
        2.7 Widget
        2.8 Titles
        2.9 Pagination
        2.10 BackToTop
        2.11 Preloader
        2.12 Breadcrumb
    03. Layout
        3.1 Header
        3.2 Footer
        3.3 Blog
        3.4 404
        3.5 Banner
    04. Section
        4.1 Price
        4.2 Service
        4.3 Feature
        4.4 Hosting
        4.5 Testimonial
        4.6 Choose Us
        4.7 Control Panel
        4.8 CTA
        4.9 FAQ
        4.10 Team
        4.11 Contact
    ********************/
/* ************************
   01.1: Reset
   ********************* */
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  outline: none !important;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: calc(26 / 16);
  color: #666666;
  text-align: left;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden !important;
}

[tabindex='-1']:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-weight: 700;
  line-height: calc(57 / 48);
  font-family: "Lato", sans-serif;
  color: #111111;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 42px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

p {
  margin-top: 0;
  margin-bottom: 20px;
}

p:last-child {
  margin-bottom: 0;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #111111;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #00C3FF;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  font-size: 1em;
  background-color: #e3e7e8;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
  display: block;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

[type='checkbox'] {
  margin-right: 5px;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

::selection {
  text-shadow: none;
  color: #ffffff;
  background-color: #00C3FF !important;
}

::placeholder {
  color: #b3b3b3 !important;
  opacity: 1;
}

iframe {
  max-width: 100%;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none !important;
}

button,
input[type='submit'] {
  border: none;
  background-color: transparent;
  cursor: pointer;
  -webkit-appearance: button;
  -moz-appearance: button;
}

textarea {
  resize: none;
}

img {
  max-width: 100%;
  height: auto;
}

table {
  width: 100%;
  max-width: 100%;
  text-align: center;
}

table th,
table td {
  padding: 20px 15px;
  border: 1px solid #EAEAEA;
}

table thead th {
  font-weight: 400;
  color: #111111;
}

table tbody td,
table tbody th {
  color: #666666;
}

pre {
  border: 1px solid;
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 0.8em 1.6em;
}

code {
  font-size: inherit;
  margin: 0;
}

kbd {
  font-weight: 700;
  color: #111111;
}

ins {
  background-color: transparent;
  text-decoration: none;
}

q {
  font-style: italic;
  text-decoration: underline;
}

mark {
  background-color: #fcf8e3;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #e6e6e6;
}

small {
  font-size: 80%;
  font-weight: 400;
}

/* ************************
   01.2: Padding/Margin
   ********************* */
.pt-120 {
  padding-top: 120px;
}

@media only screen and (max-width: 991px) {
  .pt-120 {
    padding-top: 60px;
  }
}

.pt-90 {
  padding-top: 90px;
}

@media only screen and (max-width: 991px) {
  .pt-90 {
    padding-top: 30px;
  }
}

.pt-80 {
  padding-top: 80px;
}

@media only screen and (max-width: 991px) {
  .pt-80 {
    padding-top: 20px;
  }
}

.pt-70 {
  padding-top: 70px;
}

@media only screen and (max-width: 991px) {
  .pt-70 {
    padding-top: 10px;
  }
}

.pt-60 {
  padding-top: 60px;
}

@media only screen and (max-width: 991px) {
  .pt-60 {
    padding-top: 0;
  }
}

.pt-50 {
  padding-top: 50px;
}

@media only screen and (max-width: 991px) {
  .pt-50 {
    padding-top: 0;
  }
}

.pt-40 {
  padding-top: 40px;
}

@media only screen and (max-width: 991px) {
  .pt-40 {
    padding-top: 0;
  }
}

.pb-120 {
  padding-bottom: 120px;
}

@media only screen and (max-width: 991px) {
  .pb-120 {
    padding-bottom: 60px;
  }
}

.pb-90 {
  padding-bottom: 90px;
}

@media only screen and (max-width: 991px) {
  .pb-90 {
    padding-bottom: 30px;
  }
}

.pb-80 {
  padding-bottom: 80px;
}

@media only screen and (max-width: 991px) {
  .pb-80 {
    padding-bottom: 20px;
  }
}

.pb-70 {
  padding-bottom: 70px;
}

@media only screen and (max-width: 991px) {
  .pb-70 {
    padding-bottom: 10px;
  }
}

.pb-60 {
  padding-bottom: 60px;
}

@media only screen and (max-width: 991px) {
  .pb-60 {
    padding-bottom: 0;
  }
}

.pb-50 {
  padding-bottom: 50px;
}

@media only screen and (max-width: 991px) {
  .pb-50 {
    padding-bottom: 0;
  }
}

.pb-40 {
  padding-bottom: 40px;
}

@media only screen and (max-width: 991px) {
  .pb-40 {
    padding-bottom: 0;
  }
}

.mt-60 {
  margin-top: 60px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-30 {
  margin-bottom: 30px;
}

.pt-215 {
  padding-top: 215px;
}

@media only screen and (max-width: 991px) {
  .pt-215 {
    padding-top: 155px;
  }
}

.pb-215 {
  padding-bottom: 215px;
}

@media only screen and (max-width: 991px) {
  .pb-215 {
    padding-bottom: 155px;
  }
}

/* ************************
   01.3: Color
   ********************* */
.text-color, .full-page-search .input-wrapper .input-icon i {
  color: #666666;
}

.text-color-bg {
  background-color: #666666;
}

.text-color-bo {
  border-color: #666666 !important;
}

.title-color {
  color: #111111;
}

.title-color-bg {
  background-color: #111111;
}

.title-color-bo {
  border-color: #111111 !important;
}

.border-color {
  color: #E8E8E8;
}

.border-color-bg {
  background-color: #E8E8E8;
}

.border-color-bo {
  border-color: #E8E8E8 !important;
}

.white {
  color: #ffffff;
}

.white-bg, .header-main.sticky, .offcanvas-wrapper {
  background-color: #ffffff;
}

.white-bo {
  border-color: #ffffff !important;
}

.black {
  color: #000000;
}

.black-bg {
  background-color: #000000;
}

.black-bo {
  border-color: #000000 !important;
}

.c1, .header-main ul.nav ul li a:hover, .header-main ul.nav ul li a.current-menu-children {
  color: #00C3FF;
}

.c1-bg, .header-main ul.nav ul li a:after {
  background-color: #00C3FF;
}

.c1-bo {
  border-color: #00C3FF !important;
}

.c2 {
  color: #03A87C;
}

.c2-bg {
  background-color: #03A87C;
}

.c2-bo {
  border-color: #03A87C !important;
}

a, .owl-carousel .owl-nav button, svg, svg path, svg g, i, .domain-price-list li, .video-btn .svg-wrap, .video-btn .svg-wrap:after, .btn, .pagination li a svg path,
.pagination li span svg path, .full-page-search, .offcanvas-overlay, .single-post-item, .nav-pricing li button, .hosting-price > nav .nav > button .dot, .hosting-price > nav .nav > button .dot:after, .single-service.style--five .service-content h4, .single-service.box-style, .hosting-specification-nav button, .hosting-specification-nav button:after, .pricing-toogle .toggler, .control-panel-accordion .accordion-button, .faq-accordion .accordion-header .accordion-button {
  transition: 0.3s ease-in-out;
}

.single-price-wrap .single-price-bg-shape, .single-service:after, .data_center_location-map .l_info .info-box {
  transition: 0.5s ease-in-out;
}

.domain-price-list, .feature-list, .info-list, .ts-list, .widget ul, .pagination ul, .header-main ul, .single-post-item .post-content .post-meta,
.single-post-item .post-content .post-meta-bottom, .single-price .price-body ul, .hz-price-box ul, .control-panel-content-wrap ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.single-funfact h3, .section-title.style--three h5, .single-post-item.style--two .post-content .post-meta li a, .single-post-item.style--three .post-content .post-meta li a, .single-price .price-head h2 span {
  font-weight: 400;
}

.widget.widget_tag_cloud .tagcloud a {
  font-weight: 500;
}

table tbody th, .info-list li a, .video-btn, .newsletter-form .theme-input-group .btn, .btn, .widget.widget_recent_entries.style--two ul li .posted-on, .widget.widget_nav_menu ul li a span, .widget.widget_about .contact-list li a, .section-title h5, .header.style--two .header-main ul.nav > li > a, .header.style--three .header-main ul.nav > li > a, .header.style--five .header-main ul.nav > li > a, .single-post-item .post-content .post-meta li a,
.single-post-item .post-content .post-meta-bottom li a, .single-post-item.style--two .btn-inline, .single-post-item.style--three .btn-inline, .comment-content a, .price-bottom-content a, .price span, .price sup, .nav-pricing li button, .single-price .pricing-area .price-content p, .single-price .pricing-area .price-content .price-amount sup, .single-price .pricing-area .discount p, .single-price .pricing-area .discount span, .single-price.style--two .price-body ul li, .hosting-price > nav .nav > button span:not(.dot), .hz-price-box ul li, .provider-tab .nav button, .pricing-toogle .toggler, .private-server-table thead th, .data_center_location-map .l_info .info-box h5, .testimonial-carousel.style--two .single-testimonial .testimonial-content h3, .testimonial-carousel.style--two .single-testimonial .testimonial-content .meta-info h4, .testimonial-carousel.style--two .single-testimonial .testimonial-content .meta-info p, .testimonial-carousel.style--three .single-testimonial .testimonial-content .meta-info h4, .testimonial-carousel.style--three .single-testimonial .testimonial-content .meta-info p, .single-team .team-content p {
  font-weight: 600;
}

.widget.widget_nav_menu ul li.title, .pricing-table .price-head h3,
.pricing-table .price-head .plan, .hz-price-box .price, .single-testimonial .quote-text:before, .single-testimonial .quote-text:after {
  font-weight: 700;
}

.page-title h2, .price, .single-price .price-head h2, .hosting-banner-content h2, .price-left-content h2, .single-testimonial .meta-info h5, .control-panel-content .content h3 {
  font-weight: 900;
}

.socials a:hover i, .social-links a, .btn:hover, .btn.header-btn:hover, .widget.widget_recent_entries ul li .posted-on, .widget.widget_recent_entries ul li .post-title a:hover, .widget.widget_recent_entries.text-white ul li .post-title a:hover, .widget.widget_recent_entries.style--four ul li .posted-on:hover, .widget.widget_tag_cloud .tagcloud a:hover, .widget.widget_nav_menu.text-white ul li a:hover, .widget.widget_categories ul li a:hover, .widget.widget_about .contact-list li:hover a, .widget.widget_about .contact-list li:hover i, .widget.widget_about .contact-list li span, .widget.widget_contact_info .contact-list li i, .widget.widget_social_links .socials a:hover, .blog-sidebar .widget.widget_recent_entries ul li .post-title a:hover, .section-title.style--three h5, .pagination li a,
.pagination li span, .page-title ul li a:hover, .header-main.sticky ul.nav > li > a.current-menu-parent, .header-main.sticky ul.nav > li > a:hover, .header-main.sticky ul.nav > li > a.current-menu-parent ~ .submenu-button:after, .header-main.sticky ul.nav > li > a:hover ~ .submenu-button:after, .header-main.sticky .btn.header-btn, .footer-bottom-content p a, .single-post-item .post-content .post-meta a, .single-post-item.style--two .post-content .post-meta li a:hover, .single-post-item.style--two .btn-inline:hover, .post-details .entry-header .entry-meta li a:hover, .comment-content a:hover i, .post-navigation .nav-link .nav-title:hover, .banner-content h1 .typed-wrap, .price-bottom-content a, .price, .single-price .pricing-area .price-content .price-amount, .single-price .pricing-area .discount span, .single-price-wrap.style--three .btn.btn-border, .single-service.style--four a:hover, .single-service.style--five .service-content h4:hover, .hosting-specification-nav button.active, .testimonial-carousel.style--two .single-testimonial .testimonial-content .meta-info h4, .single-team .team-content p {
  color: #00C3FF;
}

.ts-content h3:after, .social-links a:hover, .btn, .btn:hover svg, .btn.btn-white:hover, .btn-circle:hover, .widget.widget_nav_menu ul li a span, .pagination li a.current,
.pagination li span.current, .pagination li a:hover, .pagination li a.active,
.pagination li span:hover,
.pagination li span.active, .back-to-top, .preloader .spinner-grow, .header-main.sticky .btn.header-btn:hover, .typed::after, .single-price-wrap.style--three .btn.btn-border:hover, .provider-tab .nav button.active, .hosting-specification-nav button:after, .pricing-toogle .switch, .data_center_location-map .l_info .circle-ball, .data_center_location-map .l_info .circle-ball:after, .testimonial-carousel.style--three.v2 .single-testimonial-wrap:after {
  background-color: #00C3FF;
}

.socials.style--two a:hover i, .widget.widget_recent_entries.style--two ul li .posted-on, .widget.widget_recent_entries.style--two ul li .post-title a:hover, .widget.widget_nav_menu.style--two ul li a:hover, .widget.widget_about.style--two .contact-list li a, .widget.widget_about.style--two .contact-list li i, .widget.widget_about.style--two .contact-list li:hover a, .widget.widget_about.style--two .contact-list li:hover i, .widget.widget_about.style--two .contact-list li span, .section-title h2 span, .footer-bottom.style--two .footer-bottom-content p a, .banner.style--two .banner-content h1, .banner.style--two .banner-btn-group .btn-green:hover, .banner.style--two .banner-btn-group .btn-orange:hover, .single-service.style--two a:hover, .cta-content h2 span {
  color: #03A87C;
}

.btn.btn-border:hover, .btn.btn-green, .btn.btn-orange:hover, .widget.widget_nav_menu.style--two ul li a span, .back-to-top.home2, .header.style--two .header-main .btn.header-btn, .nav-pricing li button.active, .single-price-wrap .single-price-bg-shape, .data_center_location-map.style--two .l_info .info-box, .data_center_location-map.style--two .l_info .circle-ball, .data_center_location-map.style--two .l_info .circle-ball:after {
  background-color: #03A87C;
}

.owl-carousel .owl-nav button:hover i, .owl-carousel.testimonial-carousel.style--two .owl-nav button:hover i, .info-list li a, .video-btn, .social-links a:hover i, .btn, .btn.hover-style-two:hover, .btn.btn-white:hover, .btn.btn-border:hover, .btn.btn-green, .btn.btn-green:hover, .btn.btn-orange, .btn.btn-orange:hover, .btn.btn-orange.btn-border:hover, .btn.btn-style3:hover, .btn.btn-style4, .btn.btn-style4.btn-border:hover, .widget.text-white .widget-title, .widget.widget_newsletter .newsletter-content input:focus, .widget.widget_newsletter .newsletter-content label, .widget.widget_recent_entries.text-white ul li .post-title a, .widget.widget_recent_entries.style--four ul li .posted-on, .widget.widget_nav_menu ul li a span, .widget.widget_nav_menu.text-white ul li a, .widget.widget_about.text-white p, .widget.widget_about.text-white a, .section-title.text-white h2, .section-title.text-white h3, .section-title.text-white h4, .section-title.text-white h5, .section-title.text-white h6, .section-title.text-white p, .pagination li a.current,
.pagination li span.current, .pagination li a:hover, .pagination li a.active,
.pagination li span:hover,
.pagination li span.active, .page-title h2, .page-title ul li, .page-title ul li:not(:last-child):after, .page-title ul li a, .header-main.sticky .btn.header-btn:hover, .header-main .submenu-button, .header-main ul.nav > li > a, .header.style--two .header-main.sticky .btn.header-btn, .header.style--three .header-main .btn.header-btn, .header.style--three .submenu-button, .header.style--five .submenu-button, .content-404 h3, .banner-content.text-white h1, .banner-content.text-white p, .banner.style--three .banner-btn-group .btn, .nav-pricing li button.active, .nav-pricing.style--two li button.active, .single-price .price, .single-price-wrap.style--three .btn.btn-border:hover, .provider-tab .nav button.active, .single-service.text-white h4, .single-service.text-white *, .hosting-banner-content *, .private-server-table thead th, .data_center_location-map.style--two .l_info .info-box, .data_center_location-map.style--two .l_info .info-box h5, .data_center_location-map.style--three .l_info .info-box, .data_center_location-map.style--three .l_info .info-box h5, .data_center_location-map.style--four .l_info .info-box, .data_center_location-map.style--four .l_info .info-box h5, .testimonial-title.text-white h2, .cta-content.text-white h2 {
  color: #ffffff;
}

.owl-carousel button.owl-dot, .domain-price-list li, .video-btn .svg-wrap, .video-btn .svg-wrap:after, .domain-search-wrap, .funfact, .domain-search-form .input-group,
.newsletter-form .input-group, .btn svg, .btn:hover, .btn.hover-style-two:hover svg, .btn.header-btn:hover, .btn.btn-white, .header-main ul.nav > li > a.current-menu-parent:after, .header-main ul.nav > li > a:hover:after, #menu-button span, #menu-button span:before, #menu-button span:after, .header.style--three .header-main .btn.header-btn:hover, .footer-bottom .container:after, .single-post-item, .banner.style--two .banner-btn-group .btn-green:hover, .banner.style--two .banner-btn-group .btn-orange:hover, .banner.style--three .banner-btn-group .btn:hover, .banner.style--four .banner-btn-group .btn.btn-style4:hover, .pricing-table th, .pricing-table td, .nav-pricing, .single-price, .hosting-price > nav .nav > button .dot, .featured-item, .hosting-specification-nav button, .data_center_location-map .l_info .info-box, .single-testimonial, .testimonial-carousel.style--three .single-testimonial-wrap:after, .single-choose-us.style--two, .single-choose-us.style--three, .single-team .team-content, .contact-form-wrap {
  background-color: #ffffff;
}

.widget.widget_categories ul li a, .section-title h4, .post-details .entry-header .entry-meta li a, .post-navigation .nav-link .nav-title {
  color: #666666;
}

blockquote cite:after {
  background-color: #666666;
}

.ts-list li, .ts-content p, .form-control, .btn.btn-white, .btn.btn-border, .widget.widget_tag_cloud .tagcloud a, .widget.widget_nav_menu.style--two ul li.title, .header-main.sticky ul.nav > li > a, .header.style--two .header-main ul.nav > li > a, .header.style--two .submenu-button, .entry-footer .social-share p strong, .post-details .entry-header .entry-meta li span, .post-details .entry-tags ul li:first-child,
.post-details .entry-categories ul li:first-child, .banner.style--two .banner-content p, .pricing-table .price-head h3,
.pricing-table .price-head .plan, .single-price.style--two .price-body ul li span, .hz-price-box .price, .provider-tab .nav button, .single-specification .specification-content p, .pricing-toogle .toggler, .testimonial-carousel.style--two .single-testimonial .testimonial-content .meta-info p, .testimonial-carousel.style--three .single-testimonial .testimonial-content .meta-info p, .faq-accordion .accordion-header .accordion-button:not(.collapsed) {
  color: #111111;
}

.btn.hover-style-two:hover, .btn-circle, .preloader, .header-main.sticky .offcanvas-trigger span, .offcanvas-overlay {
  background-color: #111111;
}

table, .domain-price-list li, .widget.widget_newsletter .newsletter-content label, .section-title.style--three h5, .pagination li a,
.pagination li span, .post-details .entry-header .entry-meta li span, .pricing-table .price-head h3,
.pricing-table .price-head .plan, .price, .hosting-specification-nav button {
  font-family: "Lato", sans-serif;
}

.single-funfact h2, .section-title h5, .price span, .price sup, .private-server-table th, .private-server-table td, .data_center_location-map .l_info .info-box h5, .testimonial-carousel.style--two .single-testimonial .testimonial-content h3, .testimonial-carousel.style--two .single-testimonial .testimonial-content .meta-info h4, .testimonial-carousel.style--three .single-testimonial .testimonial-content .meta-info h4 {
  font-family: "Open Sans", sans-serif;
}

.owl-carousel .owl-nav button, .video-btn .svg-wrap:after, .single-funfact:after, .social-links a, .btn svg, .btn-circle, .pagination li a,
.pagination li span, .hosting-price > nav .nav > button .dot, .hosting-price > nav .nav > button .dot:after, .pricing-toogle .switch, .testimonial-carousel.style--three .single-testimonial .testimonial-img img {
  border-radius: 50%;
}

.question-form .form-control, .btn.btn-style3, .hz-price-box .hz-price-box-inner, .provider-tab .nav button, .data_center_location-map .l_info .info-box, .control-panel-accordion.style--two .accordion-button, .single-team .team-content {
  border-radius: 5px;
}

.domain-search-wrap, .funfact, .single-service.box-style, .testimonial-carousel.style--two .single-testimonial, .testimonial-carousel.style--three .single-testimonial-wrap:after, .testimonial-carousel.style--three .single-testimonial, .single-choose-us, .single-team .team-img img, .map-wrap .map, .contact-form-wrap, .contact-box {
  border-radius: 10px;
}

.newsletter-form .theme-input-group, .domain-search-form .input-group,
.newsletter-form .input-group, .btn, .btn.btn-style4, .widget.widget_nav_menu ul li a span, .nav-pricing, .nav-pricing li button {
  border-radius: 50px;
}

.domain-search-wrap, .funfact, .header-main.sticky, .single-post-item, .banner.style--two .banner-btn-group .btn-green:hover, .banner.style--two .banner-btn-group .btn-orange:hover, .banner.style--three .banner-btn-group .btn:hover, .single-price, .data_center_location-map .l_info .info-box, .testimonial-carousel {
  box-shadow: 0 0 30px rgba(24, 104, 222, 0.1);
}

.featured-item, .hosting-specification-nav button, .single-team .team-content {
  box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.05);
}

.single-price.style--two, .single-choose-us.style--two, .single-choose-us.style--three {
  box-shadow: 0px 0px 90px rgba(0, 0, 0, 0.05);
}

/* ************************
   02.1: Helper Classes
   ********************* */
.ov-hidden {
  overflow: hidden;
}

.ovx-hidden {
  overflow-x: hidden;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.section-bg {
  background-color: #FEF6F8;
}

.overlay {
  position: relative;
  z-index: 1;
}

.overlay:after {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  z-index: -1;
  content: "";
  background-color: #FBE6EA;
  opacity: .9;
}

.home2-section-bg {
  background-color: #F9F8FF;
}

.home3-section-bg {
  background-color: #F7FAFE;
}

.home4-section-bg {
  background: -moz-linear-gradient(-45deg, #123FC0 0%, #1145D9 100%);
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #123FC0), color-stop(100%, #1145D9));
  background: -webkit-linear-gradient(-45deg, #123FC0 0%, #1145D9 100%);
  background: -o-linear-gradient(-45deg, #123FC0 0%, #1145D9 100%);
  background: -ms-linear-gradient(-45deg, #123FC0 0%, #1145D9 100%);
  background: linear-gradient(-45deg, #123FC0 0%, #1145D9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#123FC0', endColorstr='#1145D9',GradientType=1);
}

.gm-pb-style--two {
  padding-bottom: 215px;
}

@media only screen and (max-width: 991px) {
  .gm-pb-style--two {
    padding-bottom: 155px;
  }
}

.meet-hostpack-img img {
  margin-top: -40px;
}

.owl-carousel .owl-nav {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 45px;
}

.owl-carousel .owl-nav button {
  width: 50px;
  height: 50px;
  background-color: #ffffff !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.owl-carousel .owl-nav button:not(:last-child) {
  margin-right: 10px;
}

.owl-carousel .owl-nav button svg path {
  fill: #00C3FF;
}

.owl-carousel .owl-nav button:hover {
  background-color: #00C3FF !important;
}

.owl-carousel .owl-nav button:hover svg path {
  fill: #ffffff;
}

.owl-carousel.testimonial-carousel .owl-nav {
  left: -44.5%;
  top: 87%;
}

@media only screen and (max-width: 1199px) {
  .owl-carousel.testimonial-carousel .owl-nav {
    display: none;
  }
}

.owl-carousel.testimonial-carousel.style--two .owl-nav {
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
  transform: none;
}

@media only screen and (max-width: 991px) {
  .owl-carousel.testimonial-carousel.style--two .owl-nav {
    display: none;
  }
}

.owl-carousel.testimonial-carousel.style--two .owl-nav button {
  width: 40px;
  height: 40px;
  border-radius: 0;
  background-color: #00C3FF !important;
}

.owl-carousel.testimonial-carousel.style--two .owl-nav button:not(:last-child) {
  margin-right: 0px;
}

.owl-carousel.testimonial-carousel.style--two .owl-nav button svg path {
  fill: #ffffff;
}

.owl-carousel.testimonial-carousel.style--two .owl-nav button.owl-prev {
  border-right: 1px solid rgba(255, 255, 255, 0.8);
}

.owl-carousel.testimonial-carousel.style--two .owl-nav button:hover {
  background-color: #00C3FF !important;
}

.owl-carousel.testimonial-carousel.style--two .owl-nav button:hover svg path {
  fill: #ffffff;
}

/* Owl Dots */
.owl-carousel .owl-dots {
  left: 50%;
  top: auto;
  bottom: -57px;
  transform: translateX(-50%);
  position: absolute;
}

.owl-carousel button.owl-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: relative;
  border: 2px solid #007BFC;
}

.owl-carousel button.owl-dot:not(:last-child) {
  margin-right: 10px;
}

.owl-carousel button.owl-dot.active {
  background-color: #007BFC;
}

.owl-carousel.team-carousel .owl-dots {
  bottom: 0;
}

.owl-carousel.team-carousel button.owl-dot {
  width: 7px;
  height: 7px;
  background-color: rgba(0, 195, 255, 0.3);
  border: none;
}

.owl-carousel.team-carousel button.owl-dot:not(:last-child) {
  margin-right: 20px;
}

.owl-carousel.team-carousel button.owl-dot.active {
  width: 12px;
  height: 12px;
  background-color: #00c3ff;
}

.owl-carousel.v2 .owl-dots {
  bottom: -43px;
}

.owl-carousel.dot-style--two button.owl-dot {
  width: 7px;
  height: 7px;
  background-color: rgba(0, 195, 255, 0.3);
  border: none;
}

.owl-carousel.dot-style--two button.owl-dot:not(:last-child) {
  margin-right: 20px;
}

.owl-carousel.dot-style--two button.owl-dot.active {
  width: 12px;
  height: 12px;
  background-color: #00c3ff;
}

.hide {
  display: none;
}

.card {
  border: none;
}

.bg-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
}

.bg-img.bg-left {
  background-position: left;
}

/* List */
.domain-price-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-right: -35px;
}

@media only screen and (max-width: 1199px) {
  .domain-price-list {
    margin-right: -20px;
  }
}

.domain-price-list li {
  box-shadow: 0 0 30px rgba(24, 104, 222, 0.05);
  text-align: center;
  border-radius: 7px;
  padding: 23px 20px 27px;
  margin-right: 35px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 1199px) {
  .domain-price-list li {
    margin-right: 20px;
  }
}

.domain-price-list li:hover {
  box-shadow: 10px 15px 35px rgba(24, 104, 222, 0.15);
}

.domain-price-list li img {
  margin-bottom: 4px;
  display: inline-block;
}

.domain-price-list li strong {
  display: block;
  color: #70799F;
}

.feature-list li {
  display: flex;
  align-items: center;
}

.feature-list li:not(:last-child) {
  margin-bottom: 40px;
}

.feature-list li .icon {
  margin-right: 30px;
}

.feature-list li .content {
  flex: 1;
}

.info-list {
  display: flex;
  align-items: center;
}

.info-list li:not(:last-child) {
  margin-right: 30px;
}

.info-list li a {
  display: inline-flex;
  align-items: center;
  font-size: 13px;
}

.info-list li a svg, .info-list li a i {
  margin-right: 5px;
}

.ts-list li:not(:last-child) {
  margin-bottom: 16px;
}

/* Form Response */
.form-response span {
  display: block;
  padding: 20px;
}

.video-btn {
  font-size: 14px;
  text-transform: uppercase;
  line-height: 1;
  display: inline-flex;
  align-items: center;
}

.video-btn .svg-wrap {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  position: relative;
}

.video-btn .svg-wrap:after {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  content: "";
  animation: ripple 2s linear infinite;
}

.video-btn .svg-wrap svg {
  position: relative;
  z-index: 2;
}

.video-btn .svg-wrap:hover {
  transform: scale(1.05);
}

.video-btn.style--two svg path {
  fill: #0364FF;
}

.video-btn:hover {
  color: #fff;
}

.video-btn:hover i {
  color: #5551EF;
}

blockquote {
  max-width: 570px;
  margin: 37px auto;
}

blockquote p {
  margin-bottom: 15px !important;
  font-style: italic;
  margin: 0 auto;
  text-decoration: underline;
}

blockquote cite {
  font-style: normal;
  font-size: 14px;
  position: relative;
  padding-left: 20px;
  line-height: 1;
}

blockquote cite:after {
  position: absolute;
  content: '';
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 2px;
  width: 10px;
}

@media only screen and (max-width: 575px) {
  br {
    display: none;
  }
}

/* Domain Search Wrap */
.domain-search-wrap {
  padding: 70px 15px 50px;
  margin-top: -130px;
  position: relative;
  z-index: 2;
}

@media only screen and (max-width: 479px) {
  .domain-search-wrap {
    padding: 40px 15px 20px;
  }
}

.domain-search-wrap .domain-price-list {
  margin-top: 45px;
}

/* Install Image */
.install-img img {
  margin-left: -9%;
  margin-right: -9%;
  margin-top: -12%;
  margin-bottom: -12%;
  max-width: initial;
}

@media only screen and (max-width: 1199px) {
  .install-img img {
    max-width: 118%;
  }
}

/* Funfact */
.funfact {
  padding: 50px 15px 15px;
  margin-bottom: -95px;
  position: relative;
  z-index: 1;
}

.funfact.style--two {
  margin-top: -95px;
  margin-bottom: 0;
}

.single-funfact {
  position: relative;
  margin-bottom: 40px;
}

.single-funfact h2 {
  font-size: 40px;
  color: #1045DE;
  margin-bottom: 14px;
}

.single-funfact h2 span:not(.counter) {
  position: relative;
}

.single-funfact h2 span:not(.counter):after {
  content: "+";
  position: absolute;
  font-size: 20px;
  top: 2px;
  width: 10px;
  height: 10px;
}

.single-funfact h3 {
  font-size: 21px;
}

.single-funfact:after {
  position: absolute;
  content: "";
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #1045DE;
  width: 8px;
  height: 8px;
}

@media only screen and (max-width: 991px) {
  .single-funfact:after {
    display: none;
  }
}

.single-funfact.style2:after {
  right: -18px;
  background-color: #FF9538;
}

.single-funfact.style2 h2 {
  color: #FF9538;
}

.single-funfact.style3:after {
  right: -26px;
  background-color: #1AE5BE;
}

.single-funfact.style3 h2 {
  color: #1AE5BE;
}

.single-funfact.style4:after {
  display: none;
}

.single-funfact.style4 h2 {
  color: #00C3FF;
}

/* Company Logo */
.logo-carousel.owl-carousel img {
  width: auto;
  display: inline;
}

/* Terms & Service */
.ts-content:not(:last-child) {
  margin-bottom: 54px;
}

.ts-content:first-child {
  margin-top: -3px;
}

.ts-content .ts-list {
  margin-top: 37px;
}

.ts-content h3 {
  padding-left: 14px;
  margin-bottom: 23px;
  position: relative;
}

.ts-content h3:after {
  width: 3px;
  height: 18px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  content: "";
}

.ts-content p:not(:last-child) {
  margin-bottom: 26px;
}

/* Gutter 50 */
@media only screen and (min-width: 992px) {
  .gutter-50 {
    margin-left: -25px;
    margin-right: -25px;
  }
  .gutter-50 > [class*="col-"] {
    padding-left: 25px;
    padding-right: 25px;
  }
}

/* Hostpack Custom Container */
.hp-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1330px) {
  .hp-container {
    padding-right: 50px;
    padding-left: 50px;
  }
}

/* ************************
   02.2: Animations
   ********************* */
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -40px, 0);
    transform: translate3d(0, -40px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 40px, 0);
    transform: translate3d(0, 40px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@keyframes matrix {
  0% {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  25% {
    transform: matrix(1, 0, 0, 1, 15, 20);
  }
  50% {
    transform: matrix(1, 0, 0, 1, -5, 25);
  }
  75% {
    transform: matrix(1, 0, 0, 1, -15, 15);
  }
  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp;
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@keyframes inner-circles-loader {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: .6;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes ripple2 {
  0% {
    transform: scale(1);
    opacity: .8;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
}

/* Custom cursor animation */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Levitate */
@-webkit-keyframes levitate {
  from {
    transform: translate(0, 15);
  }
  50% {
    transform: translate(0, -15px);
  }
  to {
    transform: translate(0, 15);
  }
}

@keyframes levitate {
  from {
    transform: translate(0, 15);
  }
  50% {
    transform: translate(0, -15px);
  }
  to {
    transform: translate(0, 15);
  }
}

/* ************************
   02.3: Social List
   ********************* */
.socials {
  display: flex;
  align-items: center;
}

.socials a {
  font-size: 18px;
}

.socials a:not(:last-child) {
  margin-right: 24px;
}

.socials.style--two a i {
  color: rgba(0, 0, 0, 0.2);
}

.social-links a {
  border: 1px solid #00C3FF;
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.social-links a:not(:last-child) {
  margin-right: 10px;
}

/* ************************
   03.4: Inputs
   ********************* */
.form-control {
  border: none;
  border-bottom: 1px solid #E8E8E8;
  height: 50px;
  border-radius: 0px;
  font-size: 14px;
  padding: 10px 15px;
  margin-bottom: 30px;
}

.form-control:focus {
  box-shadow: none;
}

textarea.form-control {
  height: 170px;
}

.newsletter-form .theme-input-group {
  display: flex;
  max-width: 540px;
  overflow: hidden;
  margin: 0 auto;
}

.newsletter-form .theme-input-group .form-control {
  margin-bottom: 0;
  height: 60px;
}

.newsletter-form .theme-input-group .btn {
  border-radius: 0px;
  padding: 10px 28px;
}

.search-form.style--two .input-wrapper {
  border: 1px solid #00C3FF;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.search-form.style--two .input-wrapper input {
  margin-bottom: 0;
  border: none;
  background-color: transparent;
}

.search-form.style--two .input-wrapper .input-icon {
  padding: 15px 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
  height: 50px;
}

.search-form.style--two .input-wrapper .input-icon:hover i {
  color: #00C3FF;
}

.domain-search-form .input-group,
.newsletter-form .input-group {
  border: 1px solid #E8E8E8;
  padding: 7px;
  max-width: 570px;
  margin: 0 auto;
}

.domain-search-form .input-group .form-control,
.newsletter-form .input-group .form-control {
  margin-bottom: 0;
  border: 0;
  border-radius: 50px !important;
}

@media only screen and (max-width: 479px) {
  .domain-search-form .input-group .form-control,
  .newsletter-form .input-group .form-control {
    height: 40px;
  }
}

.domain-search-form .input-group .btn,
.newsletter-form .input-group .btn {
  border-radius: 50px !important;
}

@media only screen and (max-width: 479px) {
  .domain-search-form .input-group .btn,
  .newsletter-form .input-group .btn {
    padding: 0px !important;
    background-color: transparent;
  }
  .domain-search-form .input-group .btn span,
  .newsletter-form .input-group .btn span {
    display: none;
  }
  .domain-search-form .input-group .btn svg,
  .newsletter-form .input-group .btn svg {
    background-color: #00C3FF;
  }
  .domain-search-form .input-group .btn svg path,
  .newsletter-form .input-group .btn svg path {
    fill: #ffffff;
  }
}

.domain-search-form .input-group .btn.btn-style4 span,
.newsletter-form .input-group .btn.btn-style4 span {
  margin-left: 4px;
}

@media only screen and (max-width: 479px) {
  .domain-search-form .input-group .btn.btn-style4,
  .newsletter-form .input-group .btn.btn-style4 {
    background-color: #0364FF;
    padding: 12px 24px !important;
  }
}

.newsletter-form .input-group {
  max-width: 660px;
}

.question-form .form-control {
  border: 1px solid #EFEFEF;
  height: 56px;
  padding: 15px 25px;
  margin-bottom: 25px;
}

.question-form textarea.form-control {
  height: 150px;
}

.login-form {
  margin-top: -6px;
}

.login-form .form-control {
  border: 1px solid #E8E8E8;
}

/* ************************
   02.5: Buttons
   ********************* */
.btn {
  font-size: 14px;
  text-transform: uppercase;
  z-index: 1;
  line-height: 1;
  padding: 5px;
  padding-left: 30px;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}

.btn svg, .btn i, .btn img {
  margin-left: 15px;
}

.btn svg {
  width: 40px;
  height: 40px;
  padding: 12px;
}

.btn:hover svg path {
  fill: #ffffff;
}

.btn:focus {
  box-shadow: none;
}

.btn.hover-style-two:hover svg path {
  fill: #111111;
}

.btn.header-btn {
  padding: 13px 28px;
  text-transform: uppercase;
  font-size: 13px;
  border: 1px solid #ffffff;
  background-color: transparent;
}

.btn.btn-lg {
  padding: 20px 40px;
}

.btn.btn-white svg g {
  stroke: #111111;
}

.btn.btn-white svg path {
  fill: #111111;
  stroke: transparent;
}

.btn.btn-white:hover svg g {
  stroke: #ffffff;
}

.btn.btn-white:hover svg path {
  fill: #ffffff;
  stroke: transparent;
}

.btn.btn-sm {
  font-size: 12px;
  padding: 4px;
  padding-left: 24px;
}

.btn.btn-sm svg {
  width: 35px;
  height: 35px;
}

.btn.btn-sm span {
  top: 1px;
}

.btn.btn-border {
  background-color: transparent;
  border-color: #03A87C;
  text-transform: capitalize;
  font-size: 14px;
  padding: 14px 30px 15px;
}

.btn.btn-border:hover {
  border-color: #03A87C;
}

.btn.btn-green {
  border-color: #03A87C;
  text-transform: capitalize;
  font-size: 17px;
  padding: 18px 36px 19px;
}

.btn.btn-green:hover {
  background-color: #F76D00;
  border-color: #F76D00;
}

.btn.btn-orange {
  background-color: #F76D00;
  border-color: #F76D00;
  text-transform: capitalize;
  font-size: 17px;
  padding: 18px 36px 19px;
}

.btn.btn-orange:hover {
  border-color: #03A87C;
}

.btn.btn-orange.btn-border {
  background-color: transparent;
  border-color: #F76D00;
  color: #F76D00;
}

.btn.btn-orange.btn-border:hover {
  background-color: #F76D00;
  border-color: #F76D00;
}

.btn.btn-orange {
  background-color: #F76D00;
  border-color: #F76D00;
  text-transform: capitalize;
  font-size: 17px;
  padding: 18px 36px 19px;
}

.btn.btn-orange:hover {
  border-color: #03A87C;
}

.btn.btn-style3 {
  background-color: transparent;
  border-color: #00C3FF;
  color: #00C3FF;
  padding: 18px 38px;
}

.btn.btn-style3:hover {
  background-color: #00C3FF;
  border-color: #00C3FF;
}

.btn.btn-style4 {
  background-color: #0364FF;
  border-color: #0364FF;
  padding: 18px 38px;
}

.btn.btn-style4:hover {
  background-color: transparent;
  border-color: #0364FF;
  color: #0364FF;
}

.btn.btn-style4.btn-border {
  background-color: transparent;
  border-color: #0364FF;
  color: #0364FF;
}

.btn.btn-style4.btn-border:hover {
  background-color: #0364FF;
  border-color: #0364FF;
}

.btn-circle {
  width: 45px;
  height: 45px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

/* Button Inline */
.btn-inline {
  color: #818181;
}

.btn-inline svg, .btn-inline i {
  margin-left: 5px;
}

.btn-inline:hover svg path {
  fill: #00C3FF;
}

.btn-inline.icon-left svg, .btn-inline.icon-left i {
  margin-left: 0;
  margin-right: 5px;
}

/* ************************
   02.6: Cards
   ********************* */
/* ************************
   02.7: Widget
   ********************* */
.widget:not(:last-child) {
  margin-bottom: 45px;
}

.widget .widget-title {
  margin-bottom: 30px;
  line-height: 1.2;
  font-size: 28px;
}

.widget.widget_newsletter .newsletter-content button {
  margin-top: 16px;
  padding: 16px 32px;
}

.widget.widget_newsletter .newsletter-content input {
  background-color: rgba(255, 255, 255, 0.09);
  border: none;
  height: 50px;
}

.widget.widget_newsletter .newsletter-content input::placeholder {
  color: #ffffff !important;
  opacity: .3;
}

.widget.widget_newsletter .newsletter-content label {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  margin-top: 16px;
  font-size: 16px;
}

.widget.widget_newsletter .newsletter-content label input[type=checkbox] {
  margin-right: 10px;
  height: auto;
  position: relative;
  top: -1px;
}

.widget.widget_newsletter.style--two .theme-input-group {
  position: relative;
  overflow: inherit;
  margin: 0;
}

.widget.widget_newsletter.style--two .theme-input-group .form-control {
  border: 1px solid #EEEEEE;
  margin-bottom: 0;
  padding: 5px 20px;
  padding-right: 35px;
  height: 45px;
}

.widget.widget_newsletter.style--two .theme-input-group button {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.widget.widget_recent_entries ul {
  background-color: #FCFBFF;
  padding: 30px 30px 32px;
}

.widget.widget_recent_entries ul li:not(:last-child) {
  margin-bottom: 26px;
}

.widget.widget_recent_entries ul li .posted-on {
  display: block;
  font-size: 11px;
  margin-bottom: 7px;
  text-transform: uppercase;
}

.widget.widget_recent_entries ul li .post-title {
  line-height: 1.529;
  font-size: 17px;
}

.widget.widget_recent_entries.text-white ul {
  background-color: rgba(13, 35, 82, 0.3);
}

.widget.widget_recent_entries.style--two ul li .posted-on {
  font-size: 12px;
}

.widget.widget_recent_entries.style--three ul {
  background-color: rgba(0, 51, 197, 0.3);
}

.widget.widget_recent_entries.style--four ul {
  background-color: rgba(252, 251, 255, 0.05);
}

.widget.widget_tag_cloud .tagcloud {
  margin: 0 -6px -6px 0;
}

.widget.widget_tag_cloud .tagcloud a {
  display: inline-block;
  padding: 5px 8px;
  background-color: #F9F9F9;
  margin: 0 3px 6px 0;
  border-radius: 2px;
  font-size: 13px;
}

.widget.widget_nav_menu ul li:not(:last-child) {
  margin-bottom: 10px;
}

.widget.widget_nav_menu ul li a {
  text-transform: capitalize;
  display: block;
}

.widget.widget_nav_menu ul li a span {
  text-transform: uppercase;
  line-height: 1;
  padding: 2px 10px;
  font-size: 12px;
  margin-left: 5px;
  position: relative;
  top: -1px;
}

.widget.widget_nav_menu ul li a span.update {
  background-color: #FF433C;
}

.widget.widget_nav_menu ul li a span.style2 {
  background-color: #0032C4;
}

.widget.widget_nav_menu ul li.title {
  font-size: 18px;
  margin-bottom: 20px;
}

.widget.widget_nav_menu.style--two ul li a span.update {
  background-color: #F76D00;
}

.widget.widget_categories ul li:not(:last-child) {
  margin-bottom: 18px;
}

.widget.widget_categories ul li a {
  position: relative;
}

.widget.widget_about .footer-logo {
  display: block;
  margin-bottom: 20px;
}

.widget.widget_about p:not(:last-child) {
  margin-bottom: 25px;
}

.widget.widget_about .contact-list {
  margin-bottom: 27px;
}

.widget.widget_about .contact-list li {
  position: relative;
}

.widget.widget_about .contact-list li a {
  font-size: 18px;
}

.widget.widget_about .contact-list li:not(:last-child) {
  margin-bottom: 10px;
}

.widget.widget_about .contact-list li i {
  margin-right: 10px;
  position: relative;
  top: -1px;
}

.widget.widget_about .accept-cards {
  margin-bottom: 30px;
}

.widget.widget_about .socials {
  margin-bottom: 21px;
}

.widget.widget_about .copyright {
  font-size: 13px;
}

.widget.widget_contact_info p {
  margin-bottom: 27px;
}

.widget.widget_contact_info .contact-list li {
  padding-left: 20px;
  position: relative;
}

.widget.widget_contact_info .contact-list li i {
  position: absolute;
  left: 0;
  top: 8px;
  font-size: 14px;
}

.widget.widget_contact ul li {
  padding-left: 30px;
  position: relative;
}

.widget.widget_contact ul li:not(:last-child) {
  margin-bottom: 14px;
}

.widget.widget_contact ul li i {
  position: absolute;
  left: 0;
  top: 5px;
}

.blog-sidebar .widget.widget_recent_entries ul {
  background-color: transparent;
  padding: 0px;
}

.blog-sidebar .widget.widget_recent_entries ul li:not(:last-child) {
  margin-bottom: 26px;
}

.blog-sidebar .widget.widget_recent_entries ul li .post-title {
  font-size: 18px;
}

.blog-sidebar .widget.widget_categories ul li a {
  padding-left: 28px;
}

.blog-sidebar .widget.widget_categories ul li a:after {
  position: absolute;
  background-image: url(../img/icon/angle-right.svg);
  background-repeat: no-repeat;
  width: 6px;
  height: 10px;
  content: "";
  left: 0;
  top: 7px;
}

/* ************************
   02.8: Titles
   ********************* */
.section-title {
  position: relative;
  margin-bottom: 67px;
}

@media only screen and (max-width: 991px) {
  .section-title {
    margin-bottom: 47px;
  }
}

.section-title.style--two {
  margin-bottom: 39px;
}

.section-title.style--two h2 {
  margin-bottom: 29px;
}

.section-title.style--two p:not(:last-child) {
  margin-bottom: 42px;
}

.section-title.feature-title h2 {
  font-size: 42px;
}

.section-title.feature-title2 h2 {
  margin-bottom: 28px;
}

.section-title.feature-title2 p:not(:last-child) {
  margin-bottom: 26px;
}

.section-title.feature-title2 .btn {
  margin-top: 16px;
}

.section-title.style--three {
  margin-bottom: 0;
}

.section-title.style--three h5 {
  margin-bottom: 29px;
}

.section-title.style--three h5.mb-n1 {
  margin-bottom: 25px;
}

.section-title.style--three h2 {
  margin-bottom: 29px;
}

.section-title.style--three p:not(:last-child) {
  margin-bottom: 42px;
}

@media only screen and (max-width: 991px) {
  .section-title.style--three {
    margin-bottom: 60px;
  }
}

.section-title.price-plan {
  margin-bottom: 46px;
}

.section-title.border-bottom {
  padding-bottom: 27px;
  border-color: rgba(0, 195, 255, 0.2);
  margin-bottom: 24px;
}

.section-title.c4 h5 {
  color: #0364FF;
}

@media only screen and (max-width: 767px) {
  .section-title br {
    display: none;
  }
}

.section-title h5 {
  text-transform: uppercase;
  margin-bottom: 24px;
  margin-top: -2px;
}

.section-title h4 {
  font-size: 21px;
  margin-bottom: 20px;
}

.section-title h2 {
  margin-top: -9px;
  margin-bottom: 25px;
  font-weight: 900;
  font-size: 48px;
  letter-spacing: -1px;
}

@media only screen and (max-width: 479px) {
  .section-title h2 {
    font-size: 36px;
  }
}

/* ************************
   02.9: Pagination
   ********************* */
@media only screen and (max-width: 991px) {
  .pagination {
    margin-top: -20px;
  }
}

.pagination li:not(:last-child) {
  margin-right: 15px;
}

.pagination li:first-child a, .pagination li:first-child span {
  transform: rotate(180deg);
}

.pagination li a,
.pagination li span {
  width: 45px;
  height: 45px;
  line-height: 1;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border: 1px solid #00C3FF;
}

.pagination li a svg path,
.pagination li span svg path {
  fill: #00C3FF;
}

.pagination li a:hover svg path, .pagination li a.active svg path,
.pagination li span:hover svg path,
.pagination li span.active svg path {
  fill: #ffffff;
}

.pagination li.nav-btn a.disabled {
  cursor: not-allowed;
  opacity: .5;
}

/* ************************
   02.10: Back to Top
   ********************* */
.back-to-top {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  width: 35px;
  height: 35px;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  color: #ffffff !important;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  line-height: 35px;
  bottom: 10%;
  right: 4%;
  transform: scale(0);
  transition: .3s ease-in;
}

.back-to-top.home4 {
  background-color: #007BFC;
}

.back-to-top.show {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
  z-index: 999;
}

.back-to-top:hover {
  opacity: .6;
}

/* ************************
   02.11: Preloader
   ********************* */
.preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ************************
   02.12: Breadcrumb
   ********************* */
.page-title-wrap {
  min-height: 540px;
  padding-top: 220px;
  padding-bottom: 100px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 479px) {
  .page-title-wrap {
    min-height: 460px;
  }
}

.page-title {
  padding-top: 5px;
}

.page-title h2 {
  margin-bottom: 22px;
  font-size: 55px;
}

.page-title ul li {
  font-size: 17px;
  position: relative;
  z-index: 1;
  line-height: 1;
}

.page-title ul li:not(:last-child) {
  padding-right: 15px;
  margin-right: 15px;
}

.page-title ul li:not(:last-child):after {
  right: -5px;
  position: absolute;
  content: '>';
  top: 0;
  width: 10px;
  height: 10px;
}

.page-title-img img {
  max-width: initial;
}

/* ************************
   03.1: Header
   ********************* */
.header-top {
  background-color: transparent;
  padding: 10px 0;
  position: relative;
  z-index: 2;
}

.header-top::after {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.2);
  content: "";
}

@media only screen and (max-width: 767px) {
  .header-top {
    padding-top: 10px;
  }
}

@media only screen and (max-width: 991px) {
  .header-top .info-bar {
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 479px) {
  .header-top .info-bar ul {
    flex-direction: column;
  }
  .header-top .info-bar ul li {
    padding-right: 0;
    margin-right: 0;
  }
  .header-top .info-bar ul li:after {
    display: none;
  }
}

.header-main {
  min-height: 80px;
  display: flex;
  align-items: center;
  background-color: transparent;
  width: 100%;
  z-index: 9;
  position: absolute;
  margin-top: 10px;
}

.header-main.fixed-top {
  position: fixed;
}

.header-main.sticky {
  margin-top: 0;
}

.header-main.sticky ul.nav > li > a.current-menu-parent:after, .header-main.sticky ul.nav > li > a:hover:after {
  display: none;
}

.header-main.sticky .logo .sticky-logo {
  display: block;
}

.header-main.sticky .logo .main-logo {
  display: none;
}

.header-main.sticky #menu-button span {
  background-color: #111111;
}

.header-main.sticky #menu-button span:before, .header-main.sticky #menu-button span:after {
  background-color: #111111;
}

.header-main.sticky .submenu-button {
  color: #111111;
}

.header-main.sticky .search-toggle-btn svg path {
  fill: #00C3FF;
}

.header-main.sticky .btn.header-btn {
  border-color: #00C3FF;
}

.header-main .logo .sticky-logo {
  display: none;
}

@media only screen and (max-width: 1199px) {
  .header-main .logo {
    max-width: 180px;
  }
  .header-main .logo svg {
    max-width: 180px;
  }
}

.header-main .submenu-button {
  position: absolute;
  right: 12px;
  top: 24px;
  height: 5px;
  width: 5px;
  font-size: 10px;
  z-index: 9999;
  transition: 0.2s ease-in-out;
}

.header-main .submenu-button:after {
  font-family: "Font Awesome 5 Free";
  content: "\f107";
  line-height: 5px;
  justify-content: center;
  display: flex;
  align-items: center;
  font-weight: 900;
}

@media only screen and (max-width: 991px) {
  .header-main .submenu-button {
    right: 0;
    top: 10px;
    height: 30px;
    width: 30px;
    color: #111111;
    font-size: 14px;
  }
  .header-main .submenu-button:after {
    line-height: 30px;
  }
  .header-main .submenu-button.submenu-opened:after {
    content: '\f106';
    color: #00C3FF;
  }
  .header-main .nav-wrap-inner {
    background-color: #fff;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.16);
    width: calc(100% - 30px);
    position: absolute;
    left: 15px;
    top: 110%;
    z-index: 9;
    max-height: 400px;
    overflow-y: auto;
  }
}

@media only screen and (max-width: 991px) {
  .header-main ul.nav {
    flex-direction: column;
    padding: 14px 20px;
    padding-right: 0;
  }
  .header-main ul.nav > li {
    width: 100%;
  }
}

.header-main ul.nav > li > a {
  line-height: 50px;
  position: relative;
  display: block;
  font-size: 17px;
}

@media only screen and (max-width: 991px) {
  .header-main ul.nav > li > a {
    color: #111111;
  }
}

.header-main ul.nav > li > a.current-menu-parent:after, .header-main ul.nav > li > a:hover:after {
  width: 20px;
  height: 2px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  bottom: 0;
  content: "";
}

.header-main ul.nav li:hover > ul {
  top: 100%;
}

.header-main ul.nav ul {
  position: absolute;
  left: 0;
  top: -2000px;
  opacity: 0;
  visibility: hidden;
  background-color: #ffffff;
  width: max-content;
  min-width: 200px;
  transform: translate3d(0, -5px, 0);
  transition: transform 0.3s ease-out;
  z-index: 9;
  border-radius: 0 0 5px 5px;
  padding: 0px;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.16);
}

@media only screen and (max-width: 991px) {
  .header-main ul.nav ul {
    position: static;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    width: 100%;
    transform: none;
  }
  .header-main ul.nav ul li {
    margin-left: 20px;
  }
  .header-main ul.nav ul li a:before, .header-main ul.nav ul li a:after {
    display: none;
  }
}

.header-main ul.nav ul li {
  padding: 8px 20px;
}

@media only screen and (max-width: 991px) {
  .header-main ul.nav ul li {
    padding-left: 0;
    padding-right: 0;
  }
}

.header-main ul.nav ul li a {
  display: block;
}

.header-main ul.nav ul li a:after {
  position: absolute;
  left: 0;
  content: "";
  transition: all 0.3s ease-out;
}

.header-main ul.nav ul li:not(:last-child) a:after {
  bottom: 0;
  height: 1px;
  width: 100%;
  opacity: 0.1;
}

.header-main ul.nav ul li:hover > ul {
  top: 0;
}

.header-main ul.nav ul ul {
  left: 100%;
}

.header-main ul.nav ul.open {
  visibility: visible;
  opacity: 1;
}

.header-main ul li {
  position: relative;
  padding: 0 25px;
}

@media only screen and (max-width: 1199px) {
  .header-main ul li {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 991px) {
  .header-main ul li {
    padding: 0;
  }
}

.header-main ul li a {
  text-transform: capitalize;
}

.header-main ul li:hover > ul {
  opacity: 1;
  visibility: visible;
  top: 100%;
  transform: translate3d(0, 0, 0);
}

@media only screen and (min-width: 992px) {
  .header-main ul.sub-menu .submenu-button {
    display: none;
  }
}

.header-right {
  display: flex;
  align-items: center;
}

.header-right > div:not(:last-child) {
  margin-right: 20px;
}

.offcanvas-trigger {
  cursor: pointer;
}

.full-page-search {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #fff;
  z-index: 9999;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
}

.full-page-search.show {
  opacity: 1;
  visibility: visible;
}

.full-page-search .search-close-btn {
  position: absolute;
  right: 15%;
  top: 15%;
  font-size: 20px;
}

.full-page-search .input-wrapper {
  position: relative;
  border-bottom: 1px solid #ddd;
  margin-bottom: 30px;
}

.full-page-search .input-wrapper input {
  width: 100%;
  font-size: 15px;
  background-color: transparent;
  border: 0;
  padding-left: 35px;
  padding-bottom: 6px;
}

.full-page-search .input-wrapper .input-icon {
  position: absolute;
  left: 10px;
  top: 0px;
}

.offcanvas-wrapper {
  overflow-x: hidden;
  overflow-y: auto;
  max-width: 400px;
  width: 100%;
  right: 0;
  left: auto;
  transition: 0.3s linear;
  transform: translateX(100%);
  z-index: 1031;
}

.offcanvas-wrapper.show {
  transform: translateX(0);
}

.offcanvas-wrapper .offcanvas-close {
  cursor: pointer;
  z-index: 999;
  top: 30px;
  right: 30px;
}

.offcanvas-wrapper .widget:not(:last-child) {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(57, 62, 92, 0.09);
}

.offcanvas-wrapper .widget .widget-title {
  margin-bottom: 15px;
  position: relative;
  padding-left: 10px;
}

.offcanvas-wrapper .widget .widget-title:before {
  position: absolute;
  left: 0;
  top: 42%;
  width: 3px;
  height: 15px;
  background-color: #03A87C;
  content: "";
  transform: translateY(-50%);
}

.offcanvas-wrapper .widget.widget_contact ul li a:hover {
  color: #03A87C;
}

.offcanvas-wrapper .widget.widget_social_links .socials a:hover i {
  color: #03A87C;
}

.offcanvas-wrapper .widget p:last-child {
  margin-bottom: 0px !important;
}

.offcanvas-wrapper .offcanvas-content {
  padding: 40px;
}

.offcanvas-wrapper .offcanvas-content .widget_logo svg {
  max-width: 180px;
}

.offcanvas-wrapper.style--two .widget .widget-title:before {
  background-color: #00C3FF;
}

.offcanvas-wrapper.style--two .widget.widget_contact ul li a:hover {
  color: #00C3FF;
}

.offcanvas-wrapper.style--two .widget.widget_social_links .socials a:hover i {
  color: #00C3FF;
}

.offcanvas-overlay {
  opacity: 0;
  visibility: hidden;
}

.offcanvas-overlay.show {
  opacity: .9;
  visibility: visible;
}

/* Menu Button */
#menu-button {
  display: none;
  height: 25px;
  width: 25px;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -2px;
  margin-right: 20px;
}

@media only screen and (max-width: 991px) {
  #menu-button {
    display: inline-flex;
  }
}

@media only screen and (max-width: 575px) {
  #menu-button {
    margin-right: 0;
  }
}

#menu-button span {
  position: relative;
  display: block;
  width: 25px;
  height: 2px;
}

#menu-button span:before, #menu-button span:after {
  position: absolute;
  content: "";
  display: block;
  width: 25px;
  height: 2px;
  transition: transform .3s ease;
}

#menu-button span:before {
  top: -7px;
}

#menu-button span:after {
  top: 7px;
}

#menu-button.menu-opened span {
  background-color: transparent !important;
}

#menu-button.menu-opened span:before {
  transform: translateY(7px) rotate(45deg);
}

#menu-button.menu-opened span:after {
  transform: translateY(-7px) rotate(-45deg);
}

.header.style--two .header-main ul.nav > li > a {
  font-size: 17px !important;
}

.header.style--two .header-main ul.nav > li > a:after {
  display: none;
}

.header.style--two .header-main ul.nav > li > a.current-menu-parent, .header.style--two .header-main ul.nav > li > a:hover {
  color: #03A87C;
}

.header.style--two .header-main ul.nav > li > a.current-menu-parent ~ .submenu-button, .header.style--two .header-main ul.nav > li > a:hover ~ .submenu-button {
  color: #03A87C;
}

.header.style--two .header-main ul.nav > li {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.header.style--two .header-main ul.nav li {
  padding: 8px 22px;
}

.header.style--two .header-main ul.nav li a {
  font-size: 16px;
  text-transform: uppercase;
}

.header.style--two .header-main ul.nav li a.current-menu-children, .header.style--two .header-main ul.nav li a:hover {
  color: #03A87C;
}

.header.style--two .header-main ul.nav li a.current-menu-children ~ .submenu-button, .header.style--two .header-main ul.nav li a:hover ~ .submenu-button {
  color: #03A87C;
}

@media only screen and (max-width: 1199px) {
  .header.style--two .header-main ul.nav li {
    padding: 8px 18px;
  }
  .header.style--two .header-main ul.nav li .submenu-button {
    right: 10px;
  }
}

@media only screen and (max-width: 991px) {
  .header.style--two .header-main ul.nav li {
    padding: 8px 0;
  }
}

.header.style--two .header-main .btn.header-btn {
  padding: 13px 24px 14px;
}

.header.style--two .header-main .btn.header-btn:hover {
  background-color: #F76D00;
}

.header.style--two .header-main.sticky ul.nav > li > a.current-menu-parent ~ .submenu-button:after, .header.style--two .header-main.sticky ul.nav > li > a:hover ~ .submenu-button:after {
  color: #03A87C;
}

.header.style--two .header-main.sticky .btn.header-btn {
  border-color: #03A87C;
}

.header.style--two .header-main.sticky .btn.header-btn:hover {
  background-color: #F76D00;
  border-color: #F76D00;
}

@media only screen and (max-width: 1199px) {
  .header.style--two .header-main .logo {
    max-width: 135px;
  }
  .header.style--two .header-main .logo svg {
    max-width: 135px;
  }
}

@media only screen and (max-width: 991px) {
  .header.style--two .header-main .logo {
    max-width: 180px;
  }
  .header.style--two .header-main .logo svg {
    max-width: 180px;
  }
}

.header.style--two .submenu-button {
  right: 12px;
  top: 23px;
}

@media only screen and (max-width: 991px) {
  .header.style--two .submenu-button {
    top: 10px;
  }
}

.header.style--two .submenu-button:after {
  content: "\f0d7";
}

.header.style--two .search-toggle-btn svg path {
  fill: #111111;
}

.header.style--two #menu-button span {
  background-color: #111111;
}

.header.style--two #menu-button span:before, .header.style--two #menu-button span:after {
  background-color: #111111;
}

.header.style--three {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.header.style--three .header-main {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.header.style--three .header-main ul.nav > li > a {
  font-size: 17px !important;
}

@media only screen and (min-width: 992px) {
  .header.style--three .header-main ul.nav > li > a {
    line-height: 80px;
  }
}

.header.style--three .header-main ul.nav > li > a.current-menu-parent, .header.style--three .header-main ul.nav > li > a:hover {
  color: #ffffff !important;
}

.header.style--three .header-main ul.nav > li > a.current-menu-parent:after, .header.style--three .header-main ul.nav > li > a:hover:after {
  background-color: #00C3FF;
}

@media only screen and (max-width: 991px) {
  .header.style--three .header-main ul.nav > li > a.current-menu-parent:after, .header.style--three .header-main ul.nav > li > a:hover:after {
    display: none;
  }
}

.header.style--three .header-main ul.nav > li > a.current-menu-parent ~ .submenu-button, .header.style--three .header-main ul.nav > li > a:hover ~ .submenu-button {
  color: #ffffff !important;
}

@media only screen and (max-width: 991px) {
  .header.style--three .header-main ul.nav > li > a.current-menu-parent, .header.style--three .header-main ul.nav > li > a:hover {
    color: #00C3FF !important;
  }
  .header.style--three .header-main ul.nav > li > a.current-menu-parent ~ .submenu-button, .header.style--three .header-main ul.nav > li > a:hover ~ .submenu-button {
    color: #00C3FF !important;
  }
}

.header.style--three .header-main ul.nav > li {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.header.style--three .header-main ul.nav li {
  padding: 8px 22px;
}

.header.style--three .header-main ul.nav li a {
  font-size: 16px;
  text-transform: uppercase;
}

.header.style--three .header-main ul.nav li a.current-menu-children, .header.style--three .header-main ul.nav li a:hover {
  color: #00C3FF;
}

.header.style--three .header-main ul.nav li a.current-menu-children ~ .submenu-button, .header.style--three .header-main ul.nav li a:hover ~ .submenu-button {
  color: #00C3FF;
}

@media only screen and (max-width: 1199px) {
  .header.style--three .header-main ul.nav li {
    padding: 8px 18px;
  }
  .header.style--three .header-main ul.nav li .submenu-button {
    right: 10px;
  }
}

@media only screen and (max-width: 991px) {
  .header.style--three .header-main ul.nav li {
    padding: 8px 0;
  }
}

.header.style--three .header-main .btn.header-btn {
  padding: 14px 24px 13px;
  font-size: 17px;
  text-transform: capitalize;
  background-color: transparent;
  border-color: #ffffff;
}

.header.style--three .header-main .btn.header-btn:hover {
  border-color: #ffffff;
  color: #00C3FF;
}

.header.style--three .header-main.sticky ul.nav > li > a.current-menu-parent, .header.style--three .header-main.sticky ul.nav > li > a:hover {
  color: #00C3FF !important;
}

.header.style--three .header-main.sticky ul.nav > li > a.current-menu-parent ~ .submenu-button, .header.style--three .header-main.sticky ul.nav > li > a:hover ~ .submenu-button {
  color: #00C3FF !important;
}

.header.style--three .header-main.sticky .btn.header-btn {
  background-color: transparent;
  border-color: #00C3FF;
  color: #00C3FF;
}

.header.style--three .header-main.sticky .btn.header-btn:hover {
  background-color: #00C3FF;
  border-color: #00C3FF;
  color: #ffffff;
}

.header.style--three .header-main.sticky #menu-button span {
  background-color: #00C3FF;
}

.header.style--three .header-main.sticky #menu-button span:before, .header.style--three .header-main.sticky #menu-button span:after {
  background-color: #00C3FF;
}

.header.style--three .header-main.sticky .offcanvas-trigger svg rect {
  fill: #00C3FF;
}

@media only screen and (max-width: 1199px) {
  .header.style--three .header-main .logo {
    max-width: 135px;
  }
  .header.style--three .header-main .logo svg {
    max-width: 135px;
  }
}

@media only screen and (max-width: 991px) {
  .header.style--three .header-main .logo {
    max-width: 180px;
  }
  .header.style--three .header-main .logo svg {
    max-width: 180px;
  }
}

.header.style--three .submenu-button {
  right: 12px;
  top: 37px;
}

@media only screen and (max-width: 991px) {
  .header.style--three .submenu-button {
    color: #111111;
  }
}

@media only screen and (max-width: 991px) {
  .header.style--three .submenu-button {
    top: 10px;
  }
}

.header.style--three .submenu-button:after {
  content: "\f0d7";
}

.header.style--three #menu-button span {
  background-color: #ffffff;
}

.header.style--three #menu-button span:before, .header.style--three #menu-button span:after {
  background-color: #ffffff;
}

.header.style--three .offcanvas-trigger svg rect {
  fill: #ffffff;
}

.header.style--four .header-main ul.nav > li > a.current-menu-parent, .header.style--four .header-main ul.nav > li > a:hover {
  color: #ffffff !important;
}

.header.style--four .header-main ul.nav > li > a.current-menu-parent ~ .submenu-button, .header.style--four .header-main ul.nav > li > a:hover ~ .submenu-button {
  color: #fff !important;
}

.header.style--four .header-main ul.nav > li > a.current-menu-parent:after, .header.style--four .header-main ul.nav > li > a:hover:after {
  background-color: #fff;
}

@media only screen and (max-width: 991px) {
  .header.style--four .header-main ul.nav > li > a.current-menu-parent:after, .header.style--four .header-main ul.nav > li > a:hover:after {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .header.style--four .header-main ul.nav > li > a.current-menu-parent, .header.style--four .header-main ul.nav > li > a:hover {
    color: #0364FF !important;
  }
  .header.style--four .header-main ul.nav > li > a.current-menu-parent ~ .submenu-button, .header.style--four .header-main ul.nav > li > a:hover ~ .submenu-button {
    color: #0364FF !important;
  }
}

.header.style--four .header-main ul.nav li a.current-menu-children, .header.style--four .header-main ul.nav li a:hover {
  color: #0364FF;
}

.header.style--four .header-main ul.nav li a.current-menu-children ~ .submenu-button, .header.style--four .header-main ul.nav li a:hover ~ .submenu-button {
  color: #0364FF;
}

.header.style--four .header-main .btn.header-btn:hover {
  color: #0364FF;
}

.header.style--four .header-main.sticky ul.nav > li > a.current-menu-parent, .header.style--four .header-main.sticky ul.nav > li > a:hover {
  color: #0364FF !important;
}

.header.style--four .header-main.sticky ul.nav > li > a.current-menu-parent ~ .submenu-button, .header.style--four .header-main.sticky ul.nav > li > a:hover ~ .submenu-button {
  color: #0364FF !important;
}

.header.style--four .header-main.sticky ul.nav > li > a.current-menu-parent ~ .submenu-button:after, .header.style--four .header-main.sticky ul.nav > li > a:hover ~ .submenu-button:after {
  color: #0364FF;
}

.header.style--four .header-main.sticky .btn.header-btn {
  background-color: transparent;
  border-color: #0364FF;
  color: #0364FF;
}

.header.style--four .header-main.sticky .btn.header-btn:hover {
  background-color: #0364FF;
  border-color: #0364FF;
  color: #ffffff;
}

.header.style--four .header-main.sticky .search-toggle-btn svg path {
  fill: #0364FF;
}

.header.style--four .header-main.sticky #menu-button span {
  background-color: #0364FF;
}

.header.style--four .header-main.sticky #menu-button span:before, .header.style--four .header-main.sticky #menu-button span:after {
  background-color: #0364FF;
}

.header.style--four #menu-button span {
  background-color: #ffffff;
}

.header.style--four #menu-button span:before, .header.style--four #menu-button span:after {
  background-color: #ffffff;
}

.header.style--five {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.header.style--five .header-main {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.header.style--five .header-main ul.nav > li > a {
  font-size: 17px !important;
}

@media only screen and (min-width: 992px) {
  .header.style--five .header-main ul.nav > li > a {
    line-height: 80px;
  }
}

.header.style--five .header-main ul.nav > li > a.current-menu-parent, .header.style--five .header-main ul.nav > li > a:hover {
  color: #ffffff !important;
}

.header.style--five .header-main ul.nav > li > a.current-menu-parent:after, .header.style--five .header-main ul.nav > li > a:hover:after {
  background-color: #00C3FF;
}

@media only screen and (max-width: 991px) {
  .header.style--five .header-main ul.nav > li > a.current-menu-parent:after, .header.style--five .header-main ul.nav > li > a:hover:after {
    display: none;
  }
}

.header.style--five .header-main ul.nav > li > a.current-menu-parent ~ .submenu-button, .header.style--five .header-main ul.nav > li > a:hover ~ .submenu-button {
  color: #ffffff !important;
}

@media only screen and (max-width: 991px) {
  .header.style--five .header-main ul.nav > li > a.current-menu-parent, .header.style--five .header-main ul.nav > li > a:hover {
    color: #00C3FF !important;
  }
  .header.style--five .header-main ul.nav > li > a.current-menu-parent ~ .submenu-button, .header.style--five .header-main ul.nav > li > a:hover ~ .submenu-button {
    color: #00C3FF !important;
  }
}

.header.style--five .header-main ul.nav > li {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.header.style--five .header-main ul.nav li {
  padding: 8px 22px;
}

.header.style--five .header-main ul.nav li a {
  font-size: 16px;
}

.header.style--five .header-main ul.nav li a.current-menu-children, .header.style--five .header-main ul.nav li a:hover {
  color: #00C3FF;
}

.header.style--five .header-main ul.nav li a.current-menu-children ~ .submenu-button, .header.style--five .header-main ul.nav li a:hover ~ .submenu-button {
  color: #00C3FF;
}

@media only screen and (max-width: 1199px) {
  .header.style--five .header-main ul.nav li {
    padding: 8px 18px;
  }
  .header.style--five .header-main ul.nav li .submenu-button {
    right: 10px;
  }
}

@media only screen and (max-width: 991px) {
  .header.style--five .header-main ul.nav li {
    padding: 8px 0;
  }
}

.header.style--five .header-main.sticky ul.nav > li > a.current-menu-parent, .header.style--five .header-main.sticky ul.nav > li > a:hover {
  color: #00C3FF !important;
}

.header.style--five .header-main.sticky ul.nav > li > a.current-menu-parent ~ .submenu-button, .header.style--five .header-main.sticky ul.nav > li > a:hover ~ .submenu-button {
  color: #00C3FF !important;
}

.header.style--five .header-main.sticky #menu-button span {
  background-color: #00C3FF;
}

.header.style--five .header-main.sticky #menu-button span:before, .header.style--five .header-main.sticky #menu-button span:after {
  background-color: #00C3FF;
}

.header.style--five .header-main.sticky .offcanvas-trigger svg rect {
  fill: #00C3FF;
}

@media only screen and (max-width: 1199px) {
  .header.style--five .header-main .logo {
    max-width: 180px;
  }
  .header.style--five .header-main .logo svg {
    max-width: 180px;
  }
}

.header.style--five .submenu-button {
  right: 12px;
  top: 39px;
}

@media only screen and (max-width: 991px) {
  .header.style--five .submenu-button {
    color: #111111;
  }
}

@media only screen and (max-width: 991px) {
  .header.style--five .submenu-button {
    top: 10px;
  }
}

.header.style--five #menu-button span {
  background-color: #ffffff;
}

.header.style--five #menu-button span:before, .header.style--five #menu-button span:after {
  background-color: #ffffff;
}

.header.style--five .offcanvas-trigger svg rect {
  fill: #ffffff;
}

/* ************************
   03.2: Footer
   ********************* */
.footer {
  position: relative;
}

.footer .widget {
  margin-bottom: 60px;
}

.footer .widget .widget-title {
  margin-bottom: 33px;
}

.footer-main {
  padding: 80px 0 20px;
}

@media only screen and (max-width: 991px) {
  .footer-main {
    padding-top: 80px;
  }
}

.footer-bottom {
  padding: 30px 0;
}

.footer-bottom .container {
  position: relative;
}

.footer-bottom .container:after {
  width: calc(100% - 30px);
  height: 1px;
  left: 15px;
  top: -30px;
  opacity: 0.15;
  position: absolute;
  content: "";
}

.footer-bottom.style--two .container:after {
  background-color: #000000;
  opacity: 0.1;
}

.footer-bottom-content {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 575px) {
  .footer-bottom-content {
    flex-direction: column;
  }
}

.footer-bottom-content p {
  max-width: 555px;
}

.footer-bottom-content.c4 p a {
  color: #007BFC;
}

/* ************************
   03.3: Blog
   ********************* */
.single-post-item {
  overflow: hidden;
  border-radius: 7px;
  margin-bottom: 30px;
}

.single-post-item.mbs2 {
  margin-bottom: 60px;
}

.single-post-item .post-thumbnail img {
  width: 100%;
}

.single-post-item .post-content {
  padding: 20px 35px 23px;
}

.single-post-item .post-content .post-title {
  margin-bottom: 10px;
  line-height: 1.416;
}

.single-post-item .post-content .post-meta,
.single-post-item .post-content .post-meta-bottom {
  display: flex;
  align-items: center;
}

.single-post-item .post-content .post-meta li:not(:last-child),
.single-post-item .post-content .post-meta-bottom li:not(:last-child) {
  margin-right: 25px;
}

@media only screen and (max-width: 1199px) {
  .single-post-item .post-content .post-meta li:not(:last-child),
  .single-post-item .post-content .post-meta-bottom li:not(:last-child) {
    margin-right: 14px;
  }
}

.single-post-item .post-content .post-meta li a,
.single-post-item .post-content .post-meta-bottom li a {
  text-transform: uppercase;
}

.single-post-item .post-content .post-meta {
  margin-bottom: 5px;
}

.single-post-item .post-content .post-meta a {
  font-size: 12px;
}

.single-post-item .post-content .post-meta-bottom a {
  font-size: 13px;
}

.single-post-item:hover {
  box-shadow: 7px 7px 45px rgba(0, 0, 0, 0.07);
}

.single-post-item.style--two {
  box-shadow: none;
}

.single-post-item.style--two .post-content {
  padding: 0;
  background-color: transparent;
  margin-bottom: -5px;
}

.single-post-item.style--two .post-content .post-meta {
  padding-top: 21px;
  margin-bottom: 5px;
}

.single-post-item.style--two .post-content .post-meta li {
  text-transform: uppercase;
  margin-right: 0;
  position: relative;
}

.single-post-item.style--two .post-content .post-meta li:not(:last-child):after {
  content: "/";
  color: #A3A3A3;
  margin: 0 3px;
}

.single-post-item.style--two .post-content .post-meta li a {
  color: #A3A3A3;
  font-size: 14px;
}

.single-post-item.style--two .btn-inline {
  text-transform: uppercase;
  font-size: 13px;
}

.single-post-item.style--two .btn-inline svg {
  margin-right: 5px;
  margin-left: 0;
  position: relative;
  top: -1px;
}

.single-post-item.style--two .btn-inline svg path {
  fill: #00C3FF;
}

.single-post-item.style--three {
  box-shadow: none;
}

.single-post-item.style--three .post-content {
  padding: 0;
  background-color: transparent;
  margin-bottom: -5px;
}

.single-post-item.style--three .post-content .post-title a:hover {
  color: #0364FF;
}

.single-post-item.style--three .post-content .post-meta {
  padding-top: 21px;
  margin-bottom: 5px;
}

.single-post-item.style--three .post-content .post-meta li {
  text-transform: uppercase;
  margin-right: 0;
  position: relative;
}

.single-post-item.style--three .post-content .post-meta li:not(:last-child):after {
  content: "/";
  color: #A3A3A3;
  margin: 0 3px;
}

.single-post-item.style--three .post-content .post-meta li a {
  color: #A3A3A3;
  font-size: 14px;
}

.single-post-item.style--three .post-content .post-meta li a:hover {
  color: #0364FF;
}

.single-post-item.style--three .btn-inline {
  text-transform: uppercase;
  font-size: 13px;
}

.single-post-item.style--three .btn-inline svg {
  margin-right: 5px;
  margin-left: 0;
  position: relative;
  top: -1px;
}

.single-post-item.style--three .btn-inline svg path {
  fill: #0364FF;
}

.single-post-item.style--three .btn-inline:hover {
  color: #0364FF;
}

/* Blog Details */
.post-details .entry-header .entry-thumbnail {
  margin-bottom: 50px;
}

.post-details .entry-header .entry-title {
  font-size: 36px;
  line-height: 1.28;
  margin-bottom: 15px;
  font-weight: 900;
}

.entry-footer {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 479px) {
  .entry-footer {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .entry-footer > div {
    width: 100%;
  }
  .entry-footer > div:not(:last-child) {
    border-bottom: 1px solid #dee2e6;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
}

.entry-footer .social-share a {
  height: auto;
  width: auto;
  border: none;
}

.entry-footer .social-share a i {
  color: #666666;
}

.entry-footer .social-share a:hover {
  background: transparent;
}

.entry-footer .social-share a:hover i {
  color: #00C3FF;
}

.post-details .entry-header .entry-meta {
  margin-bottom: 30px;
}

.post-details .entry-header .entry-meta li {
  display: inline-block;
}

.post-details .entry-header .entry-meta > li:not(:last-child) {
  margin-right: 24px;
}

.post-details .entry-header .entry-meta li span {
  font-weight: 700;
  margin-right: 7px;
}

.post-details .entry-tags ul li:first-child,
.post-details .entry-categories ul li:first-child {
  font-size: 16px;
  font-weight: 700;
  margin-right: 7px;
}

.post-details .entry-tags ul li a,
.post-details .entry-categories ul li a {
  font-size: 14px;
  line-height: 1.57;
}

.post-details .entry-tags ul li a:not(:last-child):after,
.post-details .entry-categories ul li a:not(:last-child):after {
  content: ',';
}

/* Blog Details Content */
.entry-content h1,
.entry-content h2,
.entry-content h3,
.entry-content h4,
.entry-content h5,
.entry-content h6,
.entry-content .woocommerce-checkout .form-row.notes label,
.woocommerce-checkout .form-row.notes .entry-content label,
.entry-content .login-register-wrapper .tab-btn li,
.login-register-wrapper .tab-btn .entry-content li,
.comment-content h1,
.comment-content h2,
.comment-content h3,
.comment-content h4,
.comment-content h5,
.comment-content h6,
.comment-content .woocommerce-checkout .form-row.notes label,
.woocommerce-checkout .form-row.notes .comment-content label,
.comment-content .login-register-wrapper .tab-btn li,
.login-register-wrapper .tab-btn .comment-content li,
.page--content h1,
.page--content h2,
.page--content h3,
.page--content h4,
.page--content h5,
.page--content h6,
.page--content .woocommerce-checkout .form-row.notes label,
.woocommerce-checkout .form-row.notes .page--content label,
.page--content .login-register-wrapper .tab-btn li,
.login-register-wrapper .tab-btn .page--content li {
  line-height: 1.5;
  margin-bottom: 20px;
}

.entry-content h1,
.entry-content h2,
.entry-content h3,
.entry-content h4,
.entry-content h5,
.entry-content h6 {
  padding-top: 20px;
  margin-bottom: 18px;
}

.entry-content p,
.comment-content p,
.page--content p {
  margin-bottom: 30px;
}

.entry-content table,
.comment-content table,
.page--content table {
  margin-bottom: 30px;
}

.entry-content select,
.comment-content select,
.page--content select {
  margin-bottom: 30px;
}

.entry-content > *:last-child,
.comment-content > *:last-child,
.page--content > *:last-child {
  margin-bottom: 0;
}

.comment-content a i {
  margin-right: 7px;
  font-size: 14px;
}

/* Post Author Info */
.post-author {
  padding: 45px 0 33px;
}

@media only screen and (max-width: 575px) {
  .post-author {
    flex-direction: column;
    align-items: flex-start !important;
  }
}

.post-author .author-thumb {
  margin-right: 30px;
}

@media only screen and (max-width: 575px) {
  .post-author .author-thumb {
    margin-bottom: 20px;
  }
}

.post-author .author-info h4 {
  font-size: 18px;
  margin-bottom: 5px;
}

/* Post Navigation */
.post-navigation .nav-link {
  flex-basis: 50%;
  max-width: 50%;
  padding: 0;
}

.post-navigation .nav-link:first-child {
  padding-right: 10px;
}

.post-navigation .nav-link:last-child {
  padding-left: 10px;
}

.post-navigation .nav-link:last-child .nav-title i {
  margin-right: 0;
  margin-left: 10px;
}

.post-navigation .nav-link .nav-title {
  display: block;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 17px;
}

.post-navigation .nav-link .nav-title i {
  margin-right: 10px;
}

.post-navigation .nav-link .nav-title.text-right i {
  margin-left: 10px;
}

.post-navigation .nav-link .posted-on {
  margin: 10px 0 2px;
  display: block;
  font-size: 14px;
}

.post-navigation .nav-link:hover .nav-title {
  opacity: 1;
}

/* Related Post */
.related-post h2 {
  font-size: 30px;
  margin-bottom: 30px;
}

/* Post Comments */
.comments-area .comments-title {
  margin-bottom: 42px;
}

.comments-area ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.comments-area .comment .single-comment {
  padding-bottom: 27px;
  margin-bottom: 30px;
  border-bottom: 1px solid #e2e2e2;
}

@media only screen and (max-width: 575px) {
  .comments-area .comment .single-comment {
    flex-direction: column;
  }
}

.comments-area .comment .single-comment .comment-author-image {
  min-width: 65px;
  margin-right: 30px;
  border-radius: 50%;
  overflow: hidden;
}

@media only screen and (max-width: 575px) {
  .comments-area .comment .single-comment .comment-author-image {
    margin-bottom: 16px;
  }
}

.comments-area .comment .single-comment:not(.trackback):not(.pingback) .comment-content {
  max-width: calc(100% - 95px);
  width: 100%;
}

@media only screen and (max-width: 575px) {
  .comments-area .comment .single-comment:not(.trackback):not(.pingback) .comment-content {
    max-width: 100%;
  }
}

.comments-area .comment .single-comment .comment-content .author_name {
  margin-right: 10px;
  margin-bottom: 0;
}

.comments-area .comment .single-comment .comment-content .commented-on {
  font-size: 12px;
}

.comments-area .comment .single-comment .comment-content p {
  margin: 7px 0 5px;
}

.comments-area .comment ul.children {
  margin-left: 95px;
}

@media only screen and (max-width: 575px) {
  .comments-area .comment ul.children {
    margin-left: 20px;
  }
}

/* Comments Form */
.comment-respond .comment-reply-title {
  margin-bottom: 25px;
}

.comment-respond .comment-form .comment-notes {
  margin-bottom: 42px;
}

.comment-respond .comment-form .entry-content select, .entry-content .comment-respond .comment-form select,
.comment-respond .comment-form .comment-content select, .comment-content .comment-respond .comment-form select,
.comment-respond .comment-form .page--content select, .page--content .comment-respond .comment-form select {
  margin-bottom: 35px;
}

.comment-respond .comment-form .form-control {
  border: 1px solid #EEEEEE;
  margin-bottom: 20px;
}

.comment-respond .comment-form .custom-checkbox {
  margin-bottom: 34px;
  margin-top: 4px;
}

.comment-respond p.custom-checkbox, .comment-respond p.color-container {
  display: block;
  white-space: normal;
}

/* ************************
   03.4: 404
   ********************* */
.content-404 {
  margin-top: 136px;
}

@media only screen and (max-width: 575px) {
  .content-404 {
    margin-top: 160px;
  }
}

.content-404 img {
  margin-bottom: 28px;
}

.content-404 h3 {
  margin-bottom: 35px;
}

/* ************************
   03.6: Banner
   ********************* */
.banner-shape {
  width: 100%;
  height: auto;
  left: 0;
  bottom: -1px;
  position: absolute;
}

.banner-content h1 {
  margin-bottom: 40px;
  font-size: 55px;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: -1px;
}

@media only screen and (max-width: 479px) {
  .banner-content h1 {
    font-size: 48px;
  }
}

.banner-content p {
  margin-bottom: 42px;
  font-size: 17px;
}

.banner-content .banner-price {
  position: relative;
  display: inline-block;
}

.banner-content .banner-price:after {
  width: 165px;
  height: 18px;
  left: -10px;
  bottom: -17px;
  position: absolute;
  content: "";
  background-image: url(../img/icon/banner-price-shape.png);
  background-size: contain;
}

@media only screen and (max-width: 767px) {
  .banner-content br {
    display: none;
  }
}

.banner-img {
  position: relative;
  display: inline-block;
  padding-left: 30px;
}

@media only screen and (max-width: 991px) {
  .banner-img {
    padding-left: 0;
  }
}

.banner-img .main-img {
  max-width: initial;
}

.banner-img img {
  transition: all .1s linear;
}

.banner-img img:not(.main-img) {
  position: absolute;
  left: 0;
  top: 53%;
}

.banner-img img.monitor-img {
  left: 35px;
  top: 32%;
}

.banner-img img.text-img {
  left: 81%;
  top: 48%;
}

@media only screen and (max-width: 1199px) {
  .banner-img img.text-img {
    left: 97%;
  }
}

.banner-img img.box1-img {
  left: 107%;
  top: 46%;
  animation: levitate 2.5s ease-in-out .4s infinite forwards;
}

@media only screen and (max-width: 1199px) {
  .banner-img img.box1-img {
    left: 128%;
    top: 47%;
  }
}

.banner-img img.box2-img {
  left: 97%;
  top: 35%;
  animation: levitate 2.5s ease-in-out .6s infinite forwards;
}

@media only screen and (max-width: 1199px) {
  .banner-img img.box2-img {
    left: 118%;
    top: 35%;
  }
}

.banner-img img.box3-img {
  left: 53%;
  top: 13%;
  animation: levitate 2.5s ease-in-out .1s infinite forwards;
}

@media only screen and (max-width: 1199px) {
  .banner-img img.box3-img {
    left: 62%;
  }
}

.banner-img img.box4-img {
  left: 63%;
  top: 11%;
  animation: levitate 2.5s ease-in-out infinite;
}

@media only screen and (max-width: 1199px) {
  .banner-img img.box4-img {
    left: 76%;
  }
}

.banner-btn-group {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 479px) {
  .banner-btn-group {
    flex-direction: column;
    align-items: flex-start;
  }
}

.banner-btn-group .btn:not(:last-child) {
  margin-right: 25px;
}

@media only screen and (max-width: 479px) {
  .banner-btn-group .btn:not(:last-child) {
    margin-right: 0;
    margin-bottom: 25px;
  }
}

.banner {
  padding-top: 148px;
  padding-bottom: 230px;
  min-height: 952px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

@media only screen and (max-width: 991px) {
  .banner {
    padding-top: 180px;
  }
}

.banner.banner-bg {
  background: #1D0F9B;
  background: -moz-linear-gradient(left, #1D0F9B 0%, #00C3FF 100%);
  background: -webkit-linear-gradient(left, #1D0F9B 0%, #00C3FF 100%);
  background: linear-gradient(to right, #1D0F9B 0%, #00C3FF 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1D0F9B', endColorstr='#00C3FF',GradientType=1);
}

.banner.style--two {
  padding-bottom: 0;
  background-size: cover;
  min-height: auto;
  overflow: visible;
  padding-top: 200px;
  margin-bottom: 100px;
}

@media only screen and (max-width: 575px) {
  .banner.style--two {
    padding-top: 160px;
  }
}

@media only screen and (max-width: 479px) {
  .banner.style--two {
    margin-bottom: 0;
    padding-bottom: 50px;
  }
}

.banner.style--two .banner-img {
  padding-left: 0;
  display: block;
  text-align: center;
}

.banner.style--two .banner-img img {
  position: static;
  margin-bottom: -100px;
}

@media only screen and (max-width: 479px) {
  .banner.style--two .banner-img img {
    margin-bottom: 0;
  }
}

.banner.style--two .banner-content h1 {
  margin-bottom: 25px;
}

.banner.style--two .banner-content p {
  max-width: 744px;
  margin: 0 auto 42px;
}

.banner.style--two .banner-btn-group .btn-green:hover, .banner.style--two .banner-btn-group .btn-orange:hover {
  border-color: #ffffff;
}

.banner.style--two .banner-btn-group .btn:not(:last-child) {
  margin-right: 20px;
}

@media only screen and (max-width: 479px) {
  .banner.style--two .banner-btn-group {
    align-items: center;
  }
  .banner.style--two .banner-btn-group .btn:not(:last-child) {
    margin-right: 0;
    margin-bottom: 16px;
  }
}

.banner.style--three {
  min-height: 1056px;
  padding-top: 200px;
  padding-bottom: 310px;
}

.banner.style--three .banner-content h1 {
  font-size: 90px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 575px) {
  .banner.style--three .banner-content h1 {
    font-size: 60px;
  }
}

.banner.style--three .banner-content p {
  font-size: 24px;
  margin-bottom: 56px;
}

@media only screen and (max-width: 575px) {
  .banner.style--three .banner-content p {
    font-size: 18px;
    margin-bottom: 46px;
  }
}

.banner.style--three .banner-img {
  padding-left: 0;
  margin-left: -30px;
}

.banner.style--three .banner-img img:not(.main-img) {
  position: absolute;
  left: 30px;
  top: 38%;
}

.banner.style--three .banner-img img:not(.main-img).men-img2 {
  left: 54%;
  top: 81%;
}

.banner.style--three .banner-img img:not(.main-img).men-img3 {
  left: 113%;
  top: 37%;
}

.banner.style--three .banner-btn-group .btn {
  background-color: #00C3FF;
}

.banner.style--three .banner-btn-group .btn:hover {
  border-color: #ffffff;
  color: #00C3FF;
}

@media only screen and (max-width: 479px) {
  .banner.style--three .banner-btn-group .btn:not(:last-child) {
    margin-bottom: 16px;
  }
}

.banner.style--four {
  background: -moz-linear-gradient(-45deg, #123FC0 0%, #1145D9 100%);
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #123FC0), color-stop(100%, #1145D9));
  background: -webkit-linear-gradient(-45deg, #123FC0 0%, #1145D9 100%);
  background: -o-linear-gradient(-45deg, #123FC0 0%, #1145D9 100%);
  background: -ms-linear-gradient(-45deg, #123FC0 0%, #1145D9 100%);
  background: linear-gradient(-45deg, #123FC0 0%, #1145D9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#123FC0', endColorstr='#1145D9',GradientType=1);
  padding-top: 190px;
}

@media only screen and (max-width: 767px) {
  .banner.style--four {
    padding-top: 160px;
  }
}

.banner.style--four .banner-img {
  padding-left: 0;
}

@media only screen and (max-width: 991px) {
  .banner.style--four .banner-img img {
    max-width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner.style--four .banner-content h1 {
    font-size: 46px;
  }
}

.banner.style--four .banner-btn-group .btn.btn-style4 {
  background-color: transparent;
  border-color: #ffffff;
}

.banner.style--four .banner-btn-group .btn.btn-style4:hover {
  border-color: #ffffff;
  color: #0364FF;
}

/* Add custom cursor so it auto inherits font styles */
.typed::after {
  content: '';
  display: inline-block;
  animation: blink 0.7s infinite;
  width: 2px;
  height: 40px;
}

/* Removes cursor that comes with typed.js */
.typed-cursor {
  opacity: 0;
  display: none;
}

/* ************************
   04.1: Price
   ********************* */
.pricing-plan {
  position: relative;
  z-index: 1;
}

.pricing-plan:after {
  position: absolute;
  background-image: url(../img/bg/pricing.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 472px;
  width: 100%;
  left: 0;
  top: 0;
  content: "";
  z-index: -1;
}

@media only screen and (max-width: 991px) {
  .pricing-plan:after {
    height: 372px;
  }
}

@media only screen and (max-width: 479px) {
  .pricing-plan:after {
    height: 390px;
  }
}

.price-bottom-content {
  margin-top: 43px;
}

.price-bottom-content.mt-60 {
  margin-top: 53px;
}

.price-bottom-content p {
  font-size: 18px;
}

.price-bottom-content a {
  font-size: 16px;
  display: inline-block;
  margin-left: 5px;
}

.price-bottom-content a svg {
  margin-right: 3px;
}

.pricing-table thead th, .pricing-table thead td {
  padding: 41px 15px 35px;
}

@media only screen and (max-width: 991px) {
  .pricing-table thead th, .pricing-table thead td {
    padding: 30px 15px;
  }
}

.pricing-table .price-head h3,
.pricing-table .price-head .plan {
  margin-bottom: 5px;
  font-size: 24px;
  display: block;
}

@media only screen and (max-width: 991px) {
  .pricing-table .price-head h3,
  .pricing-table .price-head .plan {
    font-size: 17px;
    margin-bottom: 14px;
  }
}

.pricing-table .price-head span {
  line-height: calc(57 / 48);
}

.pricing-table tfoot th, .pricing-table tfoot td {
  padding-top: 25px;
  padding-bottom: 25px;
}

.pricing-table th, .pricing-table td {
  white-space: nowrap;
}

.pricing-table th:first-child {
  background-color: transparent;
  border: none;
  text-align: left;
}

.price {
  font-size: 48px;
  display: block;
}

.price span, .price sup {
  font-size: 18px;
}

.price sup {
  position: relative;
  top: -24px;
  right: -5px;
}

@media only screen and (max-width: 991px) {
  .price {
    font-size: 28px;
  }
  .price sup {
    top: -10px;
  }
}

/* Home2 Price */
.price-bg-shape {
  position: relative;
  z-index: 1;
}

.price-bg-shape:after {
  width: 100%;
  height: 643px;
  left: 0;
  top: 0;
  background-image: url(../img/bg/price-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  content: "";
  z-index: -2;
}

.price-bg-shape.style--two:after {
  background-image: url(../img/bg/price-bg2.png);
  height: 637px;
}

.price-bg-shape.style--three:after {
  background-image: url(../img/bg/price-bg3.png);
  height: 562px;
}

.nav-pricing {
  justify-content: space-between;
  display: inline-flex;
  margin: 0 auto 70px;
  padding: 5px;
}

.nav-pricing li {
  position: relative;
}

.nav-pricing li:not(:last-child) {
  padding-right: 10px;
  margin-right: 9px;
}

.nav-pricing li:not(:last-child):after {
  width: 1px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  content: "";
}

.nav-pricing li button {
  padding: 11px 30px 12px;
  font-size: 16px;
}

.nav-pricing.style--two li button.active {
  background-color: #0364FF;
}

.single-price {
  border-radius: 20px 0 20px 20px;
  text-align: center;
  position: relative;
  z-index: 1;
}

.single-price .price-head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  padding: 60px 5px 35px;
}

.single-price .price-head .price-icon {
  margin-bottom: 16px;
}

.single-price .price-head .price-icon img {
  margin-left: 35px;
}

.single-price .price-head h2 {
  font-size: 30px;
  margin-bottom: 12px;
}

.single-price .price-body {
  padding: 33px 15px 55px;
}

.single-price .price-body ul {
  margin-bottom: 37px;
}

.single-price .price-body ul li:not(:last-child) {
  margin-bottom: 22px;
}

.single-price .price {
  font-size: 33px;
  margin-left: -36px;
  width: 165px;
  height: 65px;
  display: block;
  position: relative;
  margin-right: 10px;
}

.single-price .price span, .single-price .price sup {
  font-size: 12px;
}

.single-price .price sup {
  top: -17px;
}

.single-price .price span {
  margin-left: -4px;
}

.single-price .price:after {
  width: 22px;
  height: 16px;
  left: 0;
  bottom: -10px;
  position: absolute;
  content: "";
  background-image: url(../img/icon/price-after.png);
  background-repeat: no-repeat;
}

.single-price .pricing-area {
  padding: 25px 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(16, 69, 219, 0.12);
}

.single-price .pricing-area .price-content p {
  font-size: 14px;
  margin-bottom: 3px;
}

.single-price .pricing-area .price-content .price-amount {
  font-size: 48px;
  margin-left: -3px;
}

.single-price .pricing-area .price-content .price-amount sup {
  font-size: 24px;
  position: relative;
  top: -20px;
  right: -3px;
}

.single-price .pricing-area .discount {
  text-align: right;
}

.single-price .pricing-area .discount p, .single-price .pricing-area .discount span {
  font-size: 13px;
  margin-bottom: 0;
}

.single-price.style--two {
  text-align: left;
  padding: 53px 45px 60px;
  border-radius: 0;
  margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
  .single-price.style--two {
    border-radius: 5px !important;
  }
}

.single-price.style--two .price-head {
  padding: 0;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(16, 69, 219, 0.12);
}

.single-price.style--two .price-body {
  padding: 0;
}

.single-price.style--two .price-body ul {
  margin-bottom: 18px;
  padding: 25px 0;
}

.single-price.style--two .price-body ul li {
  padding-left: 28px;
  position: relative;
  font-size: 14px;
  color: #9A9A9A;
}

.single-price.style--two .price-body ul li span {
  text-transform: uppercase;
}

.single-price.style--two .price-body ul li svg {
  position: absolute;
  left: 0;
  top: 5px;
}

.single-price.style--two.first-item {
  border-radius: 5px 0 0 5px;
}

.single-price.style--two.last-item {
  border-radius: 0 5px 5px 0;
}

.single-price-wrap {
  position: relative;
  margin-bottom: 60px;
  margin-right: 30px;
}

@media only screen and (max-width: 575px) {
  .single-price-wrap {
    margin-left: 15px;
  }
}

.single-price-wrap .single-price-bg-shape {
  width: 100%;
  height: 100%;
  left: 30px;
  top: 30px;
  position: absolute;
  border-radius: 20px 0 20px 20px;
  z-index: -1;
  opacity: 0.1;
}

.single-price-wrap:hover .single-price-bg-shape {
  opacity: 1;
}

.single-price-wrap.style--two .single-price-bg-shape {
  background-color: #0364FF;
}

.single-price-wrap.style--two .single-price .price:after {
  background-image: url(../img/icon/price-after2.png);
}

.single-price-wrap.style--three .single-price-bg-shape {
  background-color: #00C3FF;
}

.single-price-wrap.style--three .single-price .price:after {
  background-image: url(../img/icon/price-after3.png);
}

.single-price-wrap.style--three .btn.btn-border {
  border-color: #00C3FF;
}

.price-section {
  margin-top: -225px;
}

/* Hosting Price */
.hosting-price > nav {
  width: 100%;
  overflow-x: auto;
  padding-left: 12px;
  margin-left: -12px;
}

.hosting-price > nav .nav {
  width: 100%;
  position: relative;
  margin-top: 12px;
  margin-bottom: 41px;
  white-space: nowrap;
  min-width: 600px;
}

.hosting-price > nav .nav:after {
  width: 100%;
  left: 0;
  top: 0;
  height: 3px;
  background-color: rgba(16, 69, 219, 0.05);
  position: absolute;
  content: "";
}

.hosting-price > nav .nav > button {
  position: relative;
  width: 20%;
  text-align: left;
  padding: 0;
}

.hosting-price > nav .nav > button .dot {
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: -6px;
  cursor: pointer;
  display: inline-block;
  z-index: 1;
}

.hosting-price > nav .nav > button .dot:after {
  background-color: #BCC6D1;
  width: 9px;
  height: 9px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  content: "";
  cursor: pointer;
}

.hosting-price > nav .nav > button span:not(.dot) {
  font-size: 14px;
  display: inline-block;
  padding-top: 33px;
  color: rgba(17, 17, 17, 0.3);
}

.hosting-price > nav .nav > button.active .dot {
  width: 40px;
  height: 40px;
  top: -18px;
  left: -12px;
}

.hosting-price > nav .nav > button.active .dot:after {
  width: 28px;
  height: 28px;
  background-image: url(../img/icon/check-round.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.hz-price-box {
  overflow-x: auto;
  padding-bottom: 10px;
}

.hz-price-box .hz-price-box-inner {
  border: 1px solid #E1E7FA;
  padding: 10px;
  padding-left: 0;
  min-width: max-content;
}

.hz-price-box ul {
  display: flex;
  align-items: center;
}

.hz-price-box ul li {
  margin-left: 30px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: rgba(17, 17, 17, 0.4);
}

.hz-price-box ul li svg {
  margin-right: 10px;
}

@media only screen and (max-width: 1199px) {
  .hz-price-box ul li {
    margin-left: 20px;
  }
}

.hz-price-box .hz-price-box-right {
  margin-left: 30px;
}

.hz-price-box .hz-price-box-right .btn {
  margin-left: 30px;
}

.hz-price-box .price {
  font-size: 24px;
}

.provider-tab > nav {
  margin-bottom: 45px;
}

.provider-tab .nav {
  margin-right: -15px;
}

.provider-tab .nav button {
  border: 1px solid rgba(16, 69, 219, 0.12);
  font-size: 16px;
  padding: 18px 38px;
  flex: 1;
  flex-wrap: wrap;
  margin-right: 15px;
  margin-bottom: 15px;
}

.provider-tab .nav button.active {
  border-color: #00C3FF;
}

/* ************************
   04.2: Service
   ********************* */
.s-service-wrap {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.s-service-wrap.style--two {
  border-color: rgba(0, 0, 0, 0.1);
}

.single-service {
  text-align: center;
  padding: 80px 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  z-index: 1;
}

.single-service .service-icon {
  margin-bottom: 23px;
}

.single-service:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(255, 255, 255, 0.1);
  opacity: 0;
  visibility: hidden;
  transform: scale(0.8);
  z-index: -1;
}

.single-service:hover:after {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.single-service.style--two {
  padding: 0;
  margin-bottom: 76px;
  border: none;
}

.single-service.style--two .service-icon {
  margin-bottom: 37px;
}

.single-service.style--two h3 {
  margin-bottom: 24px;
}

.single-service.style--two .service-content {
  max-width: 280px;
  margin: 0 auto;
}

.single-service.style--two:after {
  display: none;
}

.single-service.style--three {
  padding: 0;
  text-align: left;
  max-width: 280px;
  margin: 0 auto 76px;
}

@media only screen and (max-width: 575px) {
  .single-service.style--three {
    text-align: center;
  }
}

.single-service.style--three .service-icon {
  margin-bottom: 30px;
}

@media only screen and (max-width: 479px) {
  .single-service.style--three {
    margin-bottom: 46px;
  }
}

.single-service.style--three h3 {
  margin-bottom: 18px;
}

.single-service.style--three .service-content {
  max-width: initial;
}

.single-service.style--three:after {
  display: none;
}

.single-service.style--four {
  padding: 0;
  margin-bottom: 76px;
  border: none;
}

.single-service.style--four .service-icon {
  margin-bottom: 37px;
}

.single-service.style--four h3 {
  margin-bottom: 24px;
}

.single-service.style--four .service-content {
  max-width: 280px;
  margin: 0 auto;
}

.single-service.style--four:after {
  display: none;
}

.single-service.style--five {
  border-color: rgba(0, 0, 0, 0.1);
}

.single-service.style--five:after {
  background-color: white;
  box-shadow: 10px 10px 60px rgba(0, 0, 0, 0.05);
}

.single-service.box-style {
  box-shadow: 5px 5px 45px rgba(0, 0, 0, 0.05);
  padding: 60px 15px 57px;
  margin-bottom: 30px;
}

.single-service.box-style:hover {
  box-shadow: 10px 10px 60px rgba(0, 0, 0, 0.1);
}

.single-service.box-style.style--four {
  box-shadow: none;
  border: 1px solid rgba(255, 255, 255, 0.15);
}

.single-service.box-style.style--four:hover {
  box-shadow: none;
}

/* ************************
   04.3: Feature
   ********************* */
.feature-content .feature-list {
  margin-bottom: 42px;
}

.feature-img img {
  max-width: initial;
}

@media only screen and (max-width: 1199px) {
  .feature-img img {
    max-width: 100%;
  }
}

.feature-img img.ms-lg-n100 {
  margin-left: -100px;
}

@media only screen and (max-width: 1199px) {
  .feature-img img.ms-lg-n100 {
    margin-left: 0;
  }
}

.featured-items > .row [class*="col-"]:nth-child(2) {
  margin-top: 80px;
}

@media only screen and (max-width: 767px) {
  .featured-items > .row [class*="col-"]:nth-child(2) {
    margin-top: 0;
  }
}

.featured-items > .row [class*="col-"]:nth-child(3) {
  margin-top: -80px;
}

@media only screen and (max-width: 767px) {
  .featured-items > .row [class*="col-"]:nth-child(3) {
    margin-top: 0;
  }
}

.featured-item {
  padding: 45px 30px 42px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .featured-item {
    text-align: center;
  }
}

.featured-item .icon {
  margin-bottom: 30px;
}

.featured-item .content h3 {
  margin-bottom: 24px;
}

/* ************************
   04.4: Hosting
   ********************* */
.hosting-banner {
  min-height: 795px;
  padding-top: 232px;
  padding-bottom: 100px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 991px) {
  .hosting-banner {
    background-position: 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .hosting-banner {
    min-height: 600px;
  }
}

.hosting-banner-content h3 {
  margin-bottom: 13px;
}

.hosting-banner-content h2 {
  margin-bottom: 28px;
  font-size: 55px;
}

.hosting-banner-content p:not(:last-child) {
  margin-bottom: 42px;
}

@media only screen and (max-width: 991px) {
  .hosting-specification-nav {
    margin-bottom: 60px;
  }
}

.hosting-specification-nav button {
  font-size: 21px;
  padding: 20px;
  width: 100%;
  max-width: 285px;
  text-align: left;
  position: relative;
}

@media only screen and (max-width: 991px) {
  .hosting-specification-nav button {
    max-width: initial;
  }
}

.hosting-specification-nav button:after {
  width: 3px;
  height: 0px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  position: absolute;
}

.hosting-specification-nav button:not(:last-child) {
  margin-bottom: 20px;
}

.hosting-specification-nav button.active {
  box-shadow: 0px 0px 90px rgba(0, 0, 0, 0.07);
}

.hosting-specification-nav button.active:after {
  height: 35px;
}

.hosting-specification-tab-content {
  margin-bottom: -58px;
}

.single-specification {
  margin-bottom: 54px;
}

.single-specification .specification-icon {
  margin-bottom: 30px;
}

.single-specification .specification-content h3 {
  margin-bottom: 18px;
}

@media only screen and (max-width: 991px) {
  .price-left-content {
    margin-bottom: 60px;
  }
}

.price-left-content h2 {
  font-size: 48px;
  margin-bottom: 28px;
}

.price-left-content .pricing-toogle {
  margin: 26px 0;
}

.price-left-content .money-back {
  margin-bottom: 42px;
  font-size: 18px;
}

.pricing-toogle {
  display: flex;
  align-items: center;
}

.pricing-toogle .toggler {
  cursor: pointer;
  margin: 0;
}

.pricing-toogle .toggle {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 22px;
  border-radius: 50px;
  overflow: hidden;
  margin: 0 16px;
  border: 1px solid #00C3FF;
}

.pricing-toogle .check {
  position: absolute;
  display: block;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 6;
}

.pricing-toogle .switch {
  position: absolute;
  left: 4px;
  top: 4px;
  bottom: 4px;
  right: 57.5%;
  z-index: 1;
  transition-property: left, right;
  transition-delay: .08s,0s;
  transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.pricing-toogle .check:checked ~ .switch {
  right: 4px;
  left: 57.5%;
}

.private-server-table {
  white-space: nowrap;
}

.private-server-table.table-striped > tbody > tr:nth-of-type(even) {
  --bs-table-accent-bg: #F7F7F7;
}

.private-server-table th, .private-server-table td {
  border: none;
  vertical-align: middle;
}

.private-server-table th:first-child, .private-server-table td:first-child {
  border-radius: 5px 0 0 5px;
}

.private-server-table th:last-child, .private-server-table td:last-child {
  border-radius: 0 5px 5px 0;
}

.private-server-table th .btn, .private-server-table td .btn {
  padding: 3px;
  padding-left: 24px;
  opacity: 0;
  visibility: hidden;
}

.private-server-table th .btn svg, .private-server-table td .btn svg {
  width: 28px;
  height: 28px;
  padding: 8px;
  margin-left: 11px;
}

.private-server-table th .btn.active, .private-server-table td .btn.active {
  opacity: 1;
  visibility: visible;
}

.private-server-table thead {
  background: #1D0F9B;
  background: -moz-linear-gradient(left, #1D0F9B 0%, #00C3FF 100%);
  background: -webkit-linear-gradient(left, #1D0F9B 0%, #00C3FF 100%);
  background: linear-gradient(to right, #1D0F9B 0%, #00C3FF 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1D0F9B', endColorstr='#00C3FF',GradientType=1);
}

.private-server-table thead th {
  text-transform: uppercase;
  font-size: 15px;
  padding: 20px 10px;
}

.private-server-table tbody tr:hover .btn {
  opacity: 1;
  visibility: visible;
}

.private-server-table tbody tr td {
  padding: 15px 10px;
}

.data_center_location-map {
  position: relative;
  display: inline-block;
}

.data_center_location-map .l_info {
  position: absolute;
  left: 10%;
  top: 24%;
}

.data_center_location-map .l_info.l_info2 {
  left: 36%;
  top: 66%;
}

.data_center_location-map .l_info.l_info3 {
  left: 38%;
  top: 10px;
}

.data_center_location-map .l_info.l_info4 {
  left: 50%;
  top: 35%;
}

.data_center_location-map .l_info.l_info5 {
  left: 80%;
  top: 16%;
}

.data_center_location-map .l_info.l_info6 {
  left: 94%;
  top: 88%;
}

.data_center_location-map .l_info.l_info7 {
  left: 28%;
  top: 27%;
}

.data_center_location-map .l_info.l_info8 {
  left: 57%;
  top: 58%;
}

.data_center_location-map .l_info.l_info9 {
  left: 74%;
  top: 47%;
}

.data_center_location-map .l_info.l_info10 {
  left: 94%;
  top: 18%;
}

.data_center_location-map .l_info.l_info11 {
  left: 63%;
  top: 18%;
}

.data_center_location-map .l_info.l_info12 {
  left: 83%;
  top: 75%;
}

.data_center_location-map .l_info .circle-ball {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  z-index: 1;
  position: relative;
  cursor: pointer;
}

.data_center_location-map .l_info .circle-ball:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 50%;
  z-index: -1;
  transform: scale(1);
  animation: ripple2 1.5s 0s infinite;
}

.data_center_location-map .l_info .info-box {
  padding: 8px 15px;
  position: relative;
  position: absolute;
  width: max-content;
  transform: translateX(-50%);
  left: 0;
  bottom: 20px;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
}

.data_center_location-map .l_info .info-box:after {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 12px solid #ffffff;
  position: absolute;
  content: "";
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.data_center_location-map .l_info .info-box h5 {
  font-size: 15px;
}

.data_center_location-map .l_info.active .info-box {
  opacity: 1;
  visibility: visible;
  bottom: 30px;
}

.data_center_location-map.style--two .l_info .info-box:after {
  border-top: 12px solid #03A87C;
}

.data_center_location-map.style--three .l_info .info-box {
  background-color: #0364FF;
}

.data_center_location-map.style--three .l_info .info-box:after {
  border-top: 12px solid #0364FF;
}

.data_center_location-map.style--three .l_info .circle-ball {
  background-color: #0364FF;
}

.data_center_location-map.style--three .l_info .circle-ball:after {
  background-color: #0364FF;
}

.data_center_location-map.style--four .l_info .info-box {
  background-color: #00C3FF;
}

.data_center_location-map.style--four .l_info .info-box:after {
  border-top: 12px solid #00C3FF;
}

.data_center_location-map.style--four .l_info .circle-ball {
  background-color: #00C3FF;
}

.data_center_location-map.style--four .l_info .circle-ball:after {
  background-color: #00C3FF;
}

/* ************************
   04.5: Testimonial
   ********************* */
.testimonial {
  background-image: url(../img/bg/testimonial_bg.png);
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 991px) {
  .testimonial {
    background-image: url(../img/bg/testimonial_bg2.png);
  }
}

.testimonial4-slider {
  padding-bottom: 177px;
}

@media only screen and (max-width: 991px) {
  .testimonial4-slider {
    padding-bottom: 117px;
  }
}

.testimonial-title {
  padding-top: 98px;
}

.testimonial-title h2 {
  font-size: 48px;
  margin-bottom: 10px;
}

.testimonial-title p {
  color: #D4D4D4;
}

@media only screen and (max-width: 991px) {
  .testimonial-title {
    margin-bottom: 47px;
    padding-top: 0;
  }
}

.single-testimonial {
  display: flex;
  align-items: center;
  padding: 60px 45px;
  border-radius: 7px;
}

@media only screen and (max-width: 575px) {
  .single-testimonial {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 479px) {
  .single-testimonial {
    padding: 40px 20px;
  }
}

.single-testimonial .testimonial-img img {
  width: auto;
  display: inline;
  max-width: 235px;
}

.single-testimonial .testimonial-content {
  flex: 1;
  padding-right: 30px;
}

@media only screen and (max-width: 575px) {
  .single-testimonial .testimonial-content {
    padding-right: 0;
  }
}

.single-testimonial .rating {
  margin-bottom: 14px;
}

.single-testimonial .quote-text {
  position: relative;
  margin-bottom: 27px;
}

.single-testimonial .quote-text:before, .single-testimonial .quote-text:after {
  content: '"';
  margin-left: -12px;
  font-size: 20px;
  margin-right: 3px;
}

.single-testimonial .quote-text:after {
  margin-left: 3px;
  position: relative;
  top: 10px;
}

.single-testimonial .meta-info h5 {
  margin-bottom: 6px;
}

.single-testimonial .meta-info p {
  font-size: 15px;
}

.testimonial-carousel {
  border-radius: 7px;
  margin-top: -120px;
}

@media only screen and (max-width: 1199px) {
  .testimonial-carousel {
    overflow: hidden;
  }
}

@media only screen and (max-width: 991px) {
  .testimonial-carousel {
    margin-top: 0;
  }
}

.testimonial-carousel.style--two {
  margin-top: 0px;
}

.testimonial-carousel.style--two .single-testimonial {
  padding: 0;
  align-items: center;
  overflow: hidden;
}

@media only screen and (max-width: 991px) {
  .testimonial-carousel.style--two .single-testimonial {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}

.testimonial-carousel.style--two .single-testimonial .quote {
  margin-bottom: 20px;
}

.testimonial-carousel.style--two .single-testimonial .quote svg {
  opacity: 0.5;
}

.testimonial-carousel.style--two .single-testimonial .testimonial-content {
  padding: 60px;
  margin-bottom: -6px;
}

@media only screen and (max-width: 991px) {
  .testimonial-carousel.style--two .single-testimonial .testimonial-content {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 1199px) {
  .testimonial-carousel.style--two .single-testimonial .testimonial-content {
    padding: 30px;
  }
}

.testimonial-carousel.style--two .single-testimonial .testimonial-content h3 {
  margin-bottom: 25px;
  line-height: 1.5;
}

.testimonial-carousel.style--two .single-testimonial .testimonial-content .meta-info h4 {
  font-size: 21px;
  margin-bottom: 7px;
}

.testimonial-carousel.style--two .single-testimonial .testimonial-content .meta-info p {
  font-size: 15px;
}

.testimonial-carousel.style--two .single-testimonial .testimonial-img {
  margin-bottom: 0 !important;
}

.testimonial-carousel.style--two .single-testimonial .testimonial-img img {
  max-width: 486px;
}

@media only screen and (max-width: 991px) {
  .testimonial-carousel.style--two .single-testimonial .testimonial-img img {
    max-width: 100%;
    width: 100%;
  }
}

.testimonial-carousel.style--three {
  margin-top: 0px;
  box-shadow: none;
}

.testimonial-carousel.style--three .single-testimonial-wrap {
  padding-top: 35px;
  padding-bottom: 20px;
  padding-right: 20px;
  position: relative;
  z-index: 1;
}

.testimonial-carousel.style--three .single-testimonial-wrap:after {
  width: calc(100% - 20px);
  height: calc(100% - 55px);
  right: 0px;
  bottom: 0px;
  content: "";
  z-index: -1;
  position: absolute;
}

.testimonial-carousel.style--three .testimonial-quote-icon {
  position: absolute;
  right: 30px;
  top: 35px;
  opacity: 0.05;
}

.testimonial-carousel.style--three .single-testimonial {
  padding: 0 30px 42px;
  box-shadow: 0px 0px 90px rgba(0, 0, 0, 0.07);
  display: block;
  position: relative;
}

.testimonial-carousel.style--three .single-testimonial .testimonial-content {
  margin-top: -8px;
}

.testimonial-carousel.style--three .single-testimonial .testimonial-content .meta-info {
  margin-bottom: 17px;
}

.testimonial-carousel.style--three .single-testimonial .testimonial-content .meta-info h4 {
  color: #0364FF;
  margin-bottom: 7px;
}

.testimonial-carousel.style--three .single-testimonial .testimonial-content .meta-info p {
  font-size: 14px;
}

.testimonial-carousel.style--three .single-testimonial .testimonial-img {
  transform: translateY(-50%);
}

.testimonial-carousel.style--three .single-testimonial .testimonial-img img {
  width: 70px;
  min-width: 70px;
  height: 70px;
  border: 5px solid #1042D1;
}

.testimonial-carousel.style--three.v2 .owl-stage-outer {
  margin: -15px;
  padding: 15px;
}

.testimonial-carousel.style--three.v2 .single-testimonial-wrap .single-testimonial {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
}

.testimonial-carousel.style--three.dot-style--two .testimonial-content .meta-info h4 {
  color: #00C3FF;
}

/* ************************
   04.6: Choose Us
   ********************* */
.chooseus-section {
  margin-bottom: -166px;
}

@media only screen and (max-width: 991px) {
  .chooseus-section {
    margin-bottom: -126px;
  }
}

.single-choose-us {
  background-color: rgba(107, 88, 237, 0.1);
  padding: 70px 15px 67px;
  margin-bottom: 30px;
}

.single-choose-us .choose-us-icon {
  margin-bottom: 40px;
}

.single-choose-us .choose-us-content {
  max-width: 264px;
  margin: 0 auto;
}

.single-choose-us .choose-us-content h3 {
  margin-bottom: 20px;
}

.single-choose-us.green-bg {
  background-color: rgba(3, 168, 124, 0.1);
}

.single-choose-us.yellow-bg {
  background-color: rgba(247, 109, 0, 0.1);
}

.single-choose-us.style--two {
  padding: 60px 45px 57px;
}

.single-choose-us.style--two .choose-us-icon {
  margin-bottom: 40px;
}

.single-choose-us.style--two .choose-us-content {
  max-width: initial;
  margin: 0;
}

.single-choose-us.style--two .choose-us-content h3 {
  margin-bottom: 18px;
}

.single-choose-us.style--three {
  padding: 60px 15px 57px;
}

/* ************************
   04.7: Control Panel
   ********************* */
.control-panel-accordion {
  padding-right: 15px;
}

@media only screen and (max-width: 1199px) {
  .control-panel-accordion {
    padding-right: 0;
  }
}

.control-panel-accordion .accordion-item {
  background-color: #CEFCE9;
  border-radius: 5px;
}

.control-panel-accordion .accordion-item:nth-child(2) {
  background-color: #FFF7F2;
}

.control-panel-accordion .accordion-item:nth-child(2) .accordion-button:not(.collapsed) {
  color: #F76D00;
}

.control-panel-accordion .accordion-item:nth-child(3) {
  background-color: #F7F6FE;
}

.control-panel-accordion .accordion-item:nth-child(3) .accordion-button:not(.collapsed) {
  color: #6B58ED;
}

.control-panel-accordion .accordion-item:not(:last-child) {
  margin-bottom: 30px;
}

.control-panel-accordion .accordion-button {
  padding: 30px 30px;
  font-size: 21px;
  border: none;
  cursor: pointer;
}

.control-panel-accordion .accordion-button:not(.collapsed) {
  color: #03A87C;
  background-color: transparent;
  padding-top: 45px;
  padding-bottom: 18px;
}

.control-panel-accordion .accordion-button:after {
  display: none;
}

.control-panel-accordion .accordion-button svg {
  margin-right: 14px;
}

.control-panel-accordion .accordion-collapse {
  border: none;
}

.control-panel-accordion .accordion-collapse .accordion-body {
  padding: 0 40px 40px 80px;
}

.control-panel-accordion.style--two .accordion-item {
  background-color: rgba(0, 195, 255, 0.3);
}

.control-panel-accordion.style--two .accordion-button {
  color: #00C3FF !important;
  background-color: #ffffff;
  border: 1px solid #00C3FF;
}

.control-panel-accordion.style--two .accordion-button:not(.collapsed) {
  background-color: transparent;
  border-color: transparent;
}

.control-panel-accordion.style--two .accordion-collapse {
  border: none;
}

.control-panel-accordion.style--two .accordion-collapse .accordion-body {
  padding: 0 40px 40px 80px;
}

.control-panel-img {
  padding-left: 10px;
}

@media only screen and (max-width: 1199px) {
  .control-panel-img {
    padding-left: 0;
  }
}

@media only screen and (max-width: 479px) {
  .control-panel-img {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.control-panel-img img {
  max-width: initial;
  margin-top: -45px;
  margin-bottom: -45px;
}

@media only screen and (max-width: 991px) {
  .control-panel-img img {
    margin-top: 0;
    max-width: 100%;
  }
}

.control-panel-content-wrap ul li:not(:last-child) {
  margin-bottom: 45px;
}

.control-panel-content .icon {
  margin-right: 24px;
}

.control-panel-content .content h3 {
  margin-bottom: 24px;
}

/* ************************
   04.8: Call to Action
   ********************* */
.cta {
  position: relative;
}

.cta .cta-top {
  position: absolute;
  left: auto;
  right: 9%;
  top: 5px;
  transform: translateY(-50%);
}

@media only screen and (max-width: 991px) {
  .cta .cta-top {
    display: none;
  }
}

.cta-content h2 {
  font-size: 48px;
  margin-bottom: 45px;
  margin-top: -10px;
}

.cta-content.style--two h2 {
  margin-bottom: 35px;
}

.cta-content.style--two .cta-btn-group {
  margin-bottom: 24px;
}

.cta-content .cta-btn-group {
  margin-bottom: 34px;
}

.cta-btn-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 479px) {
  .cta-btn-group {
    flex-direction: column;
  }
}

.cta-btn-group .btn:not(:last-child) {
  margin-right: 20px;
}

@media only screen and (max-width: 479px) {
  .cta-btn-group .btn:not(:last-child) {
    margin-right: 0;
    margin-bottom: 14px;
  }
}

/* ************************
   04.9: FAQ
   ********************* */
.faq-accordion {
  margin-top: -5px;
}

.faq-accordion .accordion-item {
  border-bottom: 1px solid rgba(16, 69, 219, 0.07);
  padding-left: 20px;
}

@media only screen and (max-width: 1199px) {
  .faq-accordion .accordion-item {
    padding-left: 0;
  }
}

.faq-accordion .accordion-header .accordion-button {
  background-color: transparent;
  padding: 25px 0;
  padding-right: 30px;
  font-size: 21px;
  border: none;
  cursor: pointer;
}

.faq-accordion .accordion-header .accordion-button:after {
  background-image: url(../img/icon/accordion-plus.svg);
  margin-right: -30px;
}

.faq-accordion .accordion-header .accordion-button:not(.collapsed) {
  background-color: transparent;
}

.faq-accordion .accordion-header .accordion-button:not(.collapsed):after {
  background-image: url(../img/icon/accordion-minus.svg);
  margin-top: -16px;
}

.faq-accordion .accordion-body {
  padding: 0 60px 22px 0;
}

@media only screen and (max-width: 479px) {
  .faq-accordion .accordion-body {
    padding: 0 0 22px 0;
  }
}

.faq-accordion .accordion-collapse {
  border: none;
}

/* ************************
   04.10: Team
   ********************* */
.team-carousel .owl-stage-outer {
  margin: 0 -30px;
  padding: 0 30px 58px;
}

@media only screen and (max-width: 767px) {
  .team-carousel .owl-stage-outer {
    margin: 0 -15px;
    padding: 0 15px 58px;
  }
}

.single-team {
  margin-bottom: 30px;
}

.single-team .team-img img {
  width: 100%;
}

.single-team .team-content {
  width: calc(100% - 60px);
  position: relative;
  left: 30px;
  padding: 26px 15px 28px;
  text-align: center;
  margin-top: -57px;
}

.single-team .team-content h3 {
  margin-bottom: 8px;
}

.single-team .team-content p {
  font-size: 15px;
}

/* ************************
   04.11: Contact
   ********************* */
.company-logo-section {
  padding-bottom: 240px;
}

@media only screen and (max-width: 991px) {
  .company-logo-section {
    padding-bottom: 120px;
  }
}

.google-map-section {
  margin-top: -120px;
}

@media only screen and (max-width: 991px) {
  .google-map-section {
    margin-top: -60px;
  }
}

.map-wrap {
  position: relative;
  padding-bottom: 55px;
}

@media only screen and (max-width: 991px) {
  .map-wrap {
    padding-bottom: 0;
  }
}

.contact-form-wrap {
  max-width: 445px;
  box-shadow: 10px 10px 90px rgba(0, 0, 0, 0.1);
  padding: 57px 45px 60px;
  position: absolute;
  right: 60px;
  top: 60px;
}

@media only screen and (max-width: 991px) {
  .contact-form-wrap {
    position: static;
    margin-top: 60px;
    max-width: 100%;
  }
}

@media only screen and (max-width: 479px) {
  .contact-form-wrap {
    padding: 37px 25px 40px;
  }
}

.contact-form-wrap h3 {
  margin-bottom: 18px;
}

.contact-form-wrap > p {
  margin-bottom: 38px;
}

.contact-form .form-control {
  margin-bottom: 24px;
}

.contact-form textarea.form-control {
  height: 80px;
}

.contact-box {
  border: 1px solid #707070;
  text-align: center;
  padding: 40px 15px 37px;
  margin-bottom: 30px;
}

.contact-box .contact-icon {
  margin-bottom: 20px;
}

.contact-box .contact-info h3 {
  margin-bottom: 20px;
  font-size: 21px;
}

.contact-box .contact-info a {
  display: block;
}
