/* ************************
   03.4: Inputs
   ********************* */

   .form-control {
      border: none;
      border-bottom: 1px solid $border-color;
      height: 50px;
      border-radius: 0px;
      font-size: 14px;
      @extend %title-color;
      padding: 10px 15px;
      margin-bottom: 30px;
      &:focus {
         box-shadow: none;
      }
   }

   textarea.form-control {
      height: 170px;
   }

   .newsletter-form {
      .theme-input-group {
         display: flex;
         max-width: 540px;
         overflow: hidden;
         @extend %radius-50;
         margin: 0 auto;
         .form-control {
            margin-bottom: 0;
            height: 60px;
         }

         .btn {
            border-radius: 0px;
            padding: 10px 28px;
            @extend %semi-bold;
         }
      }
   }

   .search-form {
      &.style--two {
         .input-wrapper {
            border: 1px solid $c1;
            border-radius: 5px;
            display: flex;
            align-items: center;
            input {
               margin-bottom: 0;
               border: none;
               background-color: transparent;
            }
            .input-icon {
               padding: 15px 20px;
               display: inline-flex;
               align-items: center;
               justify-content: center;
               border-radius: 0px;
               height: 50px;
               &:hover {
                  i {
                     color: $c1;
                  }
               }
            }
         }
      }
   }

   .domain-search-form,
   .newsletter-form {
      .input-group {
         border: 1px solid $border-color;
         padding: 7px;
         @extend %white-bg;
         @extend %radius-50;
         max-width: 570px;
         margin: 0 auto;

         .form-control {
            margin-bottom: 0;
            border: 0;
            border-radius: 50px !important;
            @include mobileSm {
               height: 40px;
            }
         }
         .btn {
            border-radius: 50px !important;
            @include mobileSm {
               padding: 0px !important;
               background-color: transparent;
               span {
                  display: none;
               }
               svg {
                  background-color: $c1;
                  path {
                     fill: $white;
                  }
               }
            }
            &.btn-style4 {
               span {
                  margin-left: 4px;
               }
               @include mobileSm {
                  background-color: #0364FF;
                  padding: 12px 24px !important;
               }
            }
         }
      }
   }

   .newsletter-form {
      .input-group {
         max-width: 660px;
      }
   }

   .question-form {
      .form-control {
         border: 1px solid #EFEFEF;
         height: 56px;
         padding: 15px 25px;
         margin-bottom: 25px;
         @extend %radius-5;
      }
      textarea.form-control {
         height: 150px;
      }
   }

   .login-form {
      margin-top: -6px;
      .form-control {
         border: 1px solid #E8E8E8;
      }
   }
