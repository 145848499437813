/* ************************
   04.2: Service
   ********************* */

    .s-service-wrap {
        border-top: 1px solid rgba(#fff, 0.1);
        border-left: 1px solid rgba(#fff, 0.1);
        &.style--two {
            border-color:rgba(#000, 0.1);
        }
    }

    .single-service {
        text-align: center;
        padding: 80px 15px;
        border-right: 1px solid rgba(#fff, 0.1);
        border-bottom: 1px solid rgba(#fff, 0.1);
        position: relative;
        z-index: 1;
        .service-icon {
            margin-bottom: 23px;
        }
        &.text-white {
            h4 {
                @extend %white;
            }
        }
        &:after {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: "";
            background-color: rgba(#fff, 0.1);
            opacity: 0;
            visibility: hidden;
            transform: scale(.8);
            @extend %trans5;
            z-index: -1;
        }
        &:hover {
            &:after {
                opacity: 1;
                visibility: visible;
                transform: scale(1);
            }
        }

        &.text-white {
            * {
                @extend %white;
            }
        }

        &.style--two {
            padding: 0;
            margin-bottom: 76px;
            border: none;
            .service-icon {
                margin-bottom: 37px;
            }
            h3 {
                margin-bottom: 24px;
            }
            a {
                &:hover {
                    @extend %c2;
                }
            }
            .service-content {
                max-width: 280px;
                margin: 0 auto;
            }
            &:after {
                display: none;
            }
        }

        &.style--three {
            padding: 0;
            text-align: left;
            max-width: 280px;
            margin: 0 auto 76px;
            @include mobileMd {
                text-align: center;
            }
            .service-icon {
                margin-bottom: 30px;
            }
            @include mobileSm {
                margin-bottom: 46px;
            }
            h3 {
                margin-bottom: 18px;
            }
            .service-content {
                max-width: initial;
            }
            &:after {
                display: none;
            }
        }
        &.style--four {
            padding: 0;
            margin-bottom: 76px;
            border: none;
            .service-icon {
                margin-bottom: 37px;
            }
            h3 {
                margin-bottom: 24px;
            }
            a {
                &:hover {
                    @extend %c1;
                }
            }
            .service-content {
                max-width: 280px;
                margin: 0 auto;
            }
            &:after {
                display: none;
            }
        }
        &.style--five {
            border-color:rgba(#000, 0.1);
            &:after {
                background-color: rgba(#fff, 1);
                box-shadow: 10px 10px 60px rgba(0, 0, 0, 0.05);
            }

            .service-content {
                h4 {
                    @extend %trans3;
                    &:hover {
                        @extend %c1;
                    }
                }
            }
        }
        &.box-style {
            box-shadow: 5px 5px 45px rgba(#000, 0.05);
            @extend %radius-10;
            @extend %trans3;
            padding: 60px 15px 57px;
            margin-bottom: 30px;
            &:hover {
                box-shadow: 10px 10px 60px rgba(#000, 0.1);
            }
            &.style--four {
                box-shadow: none;
                border: 1px solid rgba(#fff, 0.15);
                &:hover {
                    box-shadow: none;
                }
            }
        }
    }